import React from 'react';
import './index.scss';

import StdPage from '../../comps/stdpage';
import SubPage from '../../comps/subpage'
import Location from '../../comps/location';
import Content from '../../comps/content';

import villa from './media/villa.jpg';
import classroom from './media/classroom.jpg';

import Image from'../../comps/image'
import Gallery from '../../comps/gallery';

//Rooms
import g_schulzimmer from './media/rooms/schulzimmer.jpg';
import g_esszimmer from './media/rooms/esszimmer.jpg';
import g_bibliothek from './media/rooms/bibliothek.jpg';

//Fotos
import header from './media/header.mp4';
import poster from './media/poster.jpg';

class Villa extends React.Component {

  render()
  {
    return (
        <StdPage title='Villa' video={header} youtubeid='IHHIVERGevk' poster={poster}>

          <SubPage naviTitle='Villa'>
            <Content>
              <h3>Villa Römerhalde</h3>
              <h2>Schule im Park</h2>

              <p>Die Villa Römerhalde in Zofingen bietet alles, was stille Konzentration fördert: Licht, Luft und Lebensraum.</p>
              <p>1974 durfte die neugegründete Ringier Journalistenschule dieses Wohnhaus der Familie Ringier als Lernstätte übernehmen.</p>
              <p>Seit einigen Jahren entdecken immer mehr Teams aus verschiedenen Abteilungen von Ringier und Ringier Medien Schweiz diesen Ort der Ruhe und Reflexion.</p>

              <p><b>Das Haus steht ihnen allen offen.</b></p>
            </Content>
          </SubPage>

          <Image src={villa} alt='Die Villa von vorne' className='fullwidth'/>

          <SubPage naviTitle='Lage'>
            <Content>
              <h2>Die Lage</h2>
              <p>
                Die Villa Römerhalde steht in einem weiten Park mit Hanglage etwas ausserhalb der Altstadt. Zu Fuss erreicht man die Liegenschaft vom Bahnhof Zofingen aus in fünfzehn Minuten.
              </p>

              <p>Adresse: Aeppenhaldenstrasse 9, 4800 Zofingen</p>
            </Content>
          </SubPage>

          <Location bounds={[7.936218, 47.278251, 7.956031, 47.290826]} point={[7.952937, 47.282656]} />

          <SubPage naviTitle='Ausstattung'>
            <Content>
              <h2>Ausstattung</h2>
              
              <p>
                <b>Drei grosse Sitzungszimmer</b>
              </p>
              <ul>
                <li>Schulzimmer: 25 Personen</li>
                <li>Esszimmer: 25 Personen</li>
                <li>Bibliothek: 20 Personen</li>
              </ul>

              <b>Ein Büro mit Pult, Sitzungstisch und Sitzgruppe</b><br />
              <b>Eine grosse, gedeckte Terrasse</b><br />
              <b>Grosse Küche, für externes Catering geeignet</b><br />

              <p><b>Für Präsentationen und Schulungen:</b></p>
              <ul>
                <li>Zwei Grossbildschirme auf Rollen</li>
                <li>Beamer und Leinwand</li>
                <li>AppleTV</li>
                <li>Zwei Pinwände</li>
                <li>Zwei Flipcharts</li>
              </ul>
              
              <Gallery className='inlinegallery'>
                <div><img src={g_schulzimmer} alt='Schulzimmer'/><span>Das Schulzimmer bietet 25 Personen Platz</span></div>
                <div><img src={g_esszimmer} alt='Esszimmer'/><span>Das Esszimmer ist für 25 Personen ausgelegt</span></div>
                <div><img src={g_bibliothek} alt='Bibliothek'/><span>20 Personen finden in der Bibliothek Platz</span></div>
              </Gallery>
            </Content>

          </SubPage>

          <Image src={classroom} alt='Schulzimmer in Benutzung' className='fullwidth'/>

          <SubPage naviTitle='Preise'>
            <Content>
              <h2>Die Preise</h2>
              <p>
                Tagespauschale: 500 Franken, plus direkte Kosten für Verpflegung und Reinigung.
              </p>
              <p>
                <b>Kontakt</b><br />
                Roman Bargezi<br />
                <a href='mailto:roman.bargezi@ringier.ch' target='_blank' rel="noopener noreferrer">roman.bargezi@ringier.ch</a>
              </p>

            </Content>
          </SubPage>

      </StdPage>
    );
  }
}

export default Villa;