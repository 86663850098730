import React from 'react';
// import './index.scss';
import './index.scss';
import '../../../node_modules/swiper/css/swiper.min.css';

import Swiper from 'react-id-swiper';

class Gallery extends React.Component {

  params = {
    // observer: true,
    // observeParents: true,
    spaceBetween: 10,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.swipernext',
      prevEl: '.swiperprev'
    }
  };

  render()
  {
    if(this.props.className === 'fullwidthgallery')
      this.params.spaceBetween = 0;
    return (
      <div className={this.props.className}>
        <Swiper {...this.params} height={this.props.height}>
          {this.props.children}
        </Swiper>
      </div>
    );
  }
}

export default Gallery;
