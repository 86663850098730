import React from 'react';

import './icons.scss';

//Icons
import iconHandwerk from './media/iconhandwerk.png';
import iconDigital from './media/icondigital.png';
import iconPraxis from './media/iconpraxisorientiert.png';

function Portrait(props)
{
  return (
    <div className='portrait'>
      <div className='imagenoborder'>
        <img src={props.src} alt={props.h1} />
      </div>
      <p className='h1'>{props.h1}</p>
      <p className='h2'>{props.h2}</p>
    </div>
  )
}

class Icons extends React.Component {

  render()
  {
    return (
      <div className='icons'>
        <Portrait src={iconDigital} h1='Digitales Handwerk' h2='Ein Journalist muss immer mehr können: Filmen mit dem Smartphone, Daten analysieren oder Social Storytelling gehören ebenso zur Ausbildung dazu.' />
        <Portrait src={iconHandwerk} h1='Klassisches Handwerk' h2='Das klassische Handwerk muss ein Journalist beherrschen. Unsere Schüler lernen es von den Besten.'/>
        <Portrait src={iconPraxis} h1='Praxisorientiert' h2='Machen, machen, machen – Unsere Schüler lernen das Handwerk praxisnah, direkt in der Redaktion, direkt von den Profis.'/>
      </div>
    );
  }
}

export default Icons;