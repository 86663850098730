import React from 'react';

import './index.scss';

import StdPage from '../../comps/stdpage';
import SubPage from '../../comps/subpage'
import Content from '../../comps/content';
import Location from '../../comps/location'

//Fotos
import header from './media/header.jpg';


class UeberUns extends React.Component {
  render()
  {
    return (
        <StdPage title='Impressum' header={header} focusPoint='18% 78%'>

          <SubPage>
            <Content>
              <h2>Impressum</h2>
              <p>
                Ringier Journalistenschule<br />
                Sophie Stehli<br />
                Dufourstrasse 23<br />
                8008 Zürich<br />
                <a href='mailto:journalistenschule@ringier.ch' target='_blank' rel="noopener noreferrer">journalistenschule@ringier.ch</a><br />
                <a href='tel:+41442596274'>+41 44 259 62 74</a>
              </p>

              <p>
                Leitung Journalistenschule: Peter Hossli
              </p>

              <p>
                Gestaltung Website: Priska Wallimann<br />
                Umsetzung: Simon Huwiler
              </p>
            </Content>
          </SubPage>

          <Location bounds={[8.519891, 47.362064, 8.558493, 47.383437]} point={[8.548143, 47.363954]} />


          {/* <SubPage naviTitle='Bildhinweise'>
            <Content>
              <h2>Bildhinweise</h2>

              <b>Einstieg</b><br />
              <ul>
                <li>Video: Journalistenschüler</li>
              </ul>

              <b>Schule</b><br />
              <ul>
                <li>Video Villa: Journalistenschüler</li>
                <li>Klassenfoto: Ringier Journalistenschule</li>
                <li>Video nachgefragt: Journalistenschüler</li>
                <li>Ehemalige Schüler: Ringier Journalistenschule</li>
                <li>Porträt Praktiker: Zur Verfügung gestellt</li>
                <li>Villa: Ringier Journalistenschule</li>
                <li>Heinrich Oswald: Ringier</li>
              </ul>

              <b>Geschichte</b>
              <ul>
                <li>Heinrich Oswald: Ringier</li>
                <li>Villa: Ringier Journalistenschule</li>
                <li>Erstes Klassenfoto: Sammelband</li>
              </ul>
            </Content>
          </SubPage> */}

      </StdPage>
    );
  }
}

export default UeberUns;