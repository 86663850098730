function createKeyFromString(s)
{
  if(s === undefined)
    return "";
    
  return s.toLowerCase().replaceAll(" ", "").replace(/^\d*/, "")
}
/* 
String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
}; */

export default {
  createKeyFromString: createKeyFromString
}