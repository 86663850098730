import React from 'react';
import mapboxgl from 'mapbox-gl';
import variables from '../../shared.scss'

import './index.scss';

class SubPage extends React.Component {

  componentDidMount()
  {
    // mapboxgl.accessToken = "pk.eyJ1IjoiYmxpY2stc3Rvcnl0ZWxsaW5nIiwiYSI6ImNpcjNiaWFsZjAwMThpM25xMzIxcXM1bzcifQ.XJat3GcYrmg9o-0oAaz3kg";
    mapboxgl.accessToken = "pk.eyJ1IjoiYmxpY2stc3Rvcnl0ZWxsaW5nIiwiYSI6ImNrbzl0OHQ3ODJpejIyd2x5Z2FieWg5c2kifQ.k4plPTM9wtm6jAarEe-Reg";
    this.map = new mapboxgl.Map({
      container: document.querySelector('#locationmap'),
      style: 'mapbox://styles/blick-storytelling/cjghx2h8f00582rs2kk0h3c3r',
      // style: 'mapbox://styles/mapbox/outdoors-v11',
      
      zoom: 14,
      center: this.props.point,
      attributionControl: false,
      interactive: false,
    });

    this.map.jumpTo(this.map.cameraForBounds(this.props.bounds));

    //7.952937, 47.282656

    this.map.on('load', () => {
      new mapboxgl.Marker({
          color: variables.outlinecolor
        })
        .setLngLat(this.props.point)
        .addTo(this.map);
    });
  }

  render()
  {
    return (
      <div id='locationmap'></div>
    );
  }
}

export default SubPage;