import React from 'react';
import './index.scss';
import Collapse from "@kunukn/react-collapse";

class CollapseText extends React.Component {

  state = {collapse: false}

  render()
  {
    if(this.props.className === 'fullwidthgallery')
      this.params.spaceBetween = 0;
    return (
      <React.Fragment>
        <p>
          <span className={`collapse ${this.state.collapse ? 'up' : 'down'}`} onClick={() => this.setState({collapse: !this.state.collapse})}>{this.props.text}</span>
        </p>
        <Collapse isOpen={this.state.collapse}>
          {this.props.children}
        </Collapse>
      </React.Fragment>
    );
  }
}

export default CollapseText;
