import React from 'react';
import './index.scss';

import Navigation from '../../comps/navigation'

import introvideo from './media/intro.mp4';
import poster from './media/poster.jpg';

// import {YoutubeController} from '../../comps/youtubecontroller';

class Home extends React.Component {

  state = {activateFullscreen: false, fadeIn: false}

  componentDidMount()
  {    
    setTimeout(() => this.setState({fadeIn: true}), 1000);
  }

  render()
  {
    return (
      <React.Fragment>

        {/* <YoutubeController videoid='rLUOrdxxO4o' activate={this.state.activateFullscreen} onClose={() => this.setState({activateFullscreen: false})}/> */}

        <header id='front3'>

          <Navigation />

          <div id='title' className={this.state.fadeIn ? 'fadeIn' : 'hide'}>
            {/* <h2>Ringier Journalistenschule</h2> */}            
            <h1>«Was spricht eigentlich noch für die Kultur der&nbsp;Fragen?»</h1>
            <h3>Frank A. Meyer, Publizist Ringier</h3>
          </div>

          <div id='videocontainer'>
            <video id='fullscreenvideo' width='100%' height='100%' autoPlay muted autobuffer="true" controlsList="nodownload" loop playsInline={true} poster={poster}>
              <source src={introvideo} type="video/mp4" />
            </video>
          </div>

          {/* Controller */}
          {/* <YoutubeControllerIcon onClick={() => this.setState({activateFullscreen: true})} /> */}

        </header>
      </React.Fragment>
    );
  }
}

export default Home;
