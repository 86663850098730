import React from 'react';

import './index.scss';

import StdPage from '../../comps/stdpage';
import SubPage from '../../comps/subpage'
import Content from '../../comps/content';
import Location from '../../comps/location'

//Fotos
import header from './media/header.jpg';


class UeberUns extends React.Component {
  render()
  {
    return (
        <StdPage title='Kontakt' header={header} focusPoint='85% 48%'>

          <SubPage naviTitle='Kontakt' className='bottomline'>
            <Content>
              <h2>Kontakt</h2>
              <p>
                Schreiben Sie uns eine Mail an:<br />
                <a href='mailto:journalistenschule@ringier.ch' target='_blank' rel="noopener noreferrer">journalistenschule@ringier.ch</a>
              </p>

              <p>
                oder rufen Sie uns an unter:<br />
                <a href='tel:+41442596274'>+41 44 259 62 74</a>
              </p>
            </Content>
          </SubPage>

          <SubPage naviTitle='Adresse'>
            <Content>
              <h2>Adresse</h2>
              <p>
                <b>Unsere Adresse lautet:</b><br />
                Ringier Journalistenschule<br />
                Sophie Stehli<br />
                Dufourstrasse 23<br />
                8008 Zürich
              </p>
            </Content>
          </SubPage>

          <Location bounds={[8.519891, 47.362064, 8.558493, 47.383437]} point={[8.548143, 47.363954]} />

      </StdPage>
    );
  }
}

export default UeberUns;