import React from 'react';

import './schulklasse.scss';

//Aktuelle Dozenten
import ulrike from './media/dozenten/ulrike.jpg';
import jonas from './media/dozenten/jonas.jpg';
import fabian from './media/dozenten/fabian.jpg';
// import viktor from './media/dozenten/viktor.jpg';
import michael from '../ueberuns/media/stiftungsraete/haller.jpg';
import peter from './media/dozenten/peter.jpg';
import magdalena from '../ueberuns/media/stiftungsraete/kauz.jpg';
import erwin from './media/dozenten/erwin.jpg';
import steiner from './media/dozenten/steiner.jpg';
// import linden from './media/dozenten/linden.jpg';
import isabelle from './media/dozenten/isabelle.jpg';
// import marco from './media/dozenten/marco.jpg';
import julian from './media/dozenten/julian.jpg';
import matthias from './media/dozenten/matthias.jpg';
import constantin from './media/dozenten/constantin.jpg';
import nasr from './media/dozenten/nasr.jpg';
import meier from './media/dozenten/meier.jpg';
import schmidt from './media/dozenten/schmidt.jpg';

function Portrait(props)
{
  return (
    <div className='portrait'>
      <div className='image'>
        <img src={props.src} alt={props.h1} />
      </div>
      <div className='text'>
        <p className='h1'>{props.h1}</p>
        <p className='h2'>{props.h2}</p>
        <p className='h3'>{props.h3}</p>
      </div>
    </div>
  )
}

class Schulklasse extends React.Component {
  render()
  {
    return (
      <React.Fragment>
        <div className='schulklasse'>
          <Portrait src={jonas} h1='Jonas Bayona' h2='izzy-magazin.ch' h3='Social Content' />
          {/* <Portrait src={viktor} h1='Viktor Dammann,' h2='BLICK-Gruppe' h3='Polizei- und Gerichtsreporter' /> */}
          <Portrait src={fabian} h1='Fabian Eberhard' h2='BLICK-Gruppe' h3='Recherche' />
          <Portrait src={michael} h1='Michael Haller' h2='em. Prof. Publizistik Leipzig' h3='Recherche' />
          <Portrait src={peter} h1='Peter Hossli' h2='Reporter' h3='Reportage' />
          <Portrait src={magdalena} h1='Magdalena Kauz' h2='Dokumentarfilmerin' h3='Video' />
          <Portrait src={erwin} h1='Erwin Koch' h2='Autor' h3='Schreibwerkstatt' />
          {/* <Portrait src={linden} h1='Peter Linden' h2='Freelancer' h3='Journalistische Formate' /> */}
          <Portrait src={meier} h1='Nicole Meier' h2='Chefredaktorin SDA' h3='Nachricht' />
          <Portrait src={nasr} h1='Susanne Nasr' h2='Dozentin für digitale Sicherheit' h3='Datensicherheit' />
          <Portrait src={isabelle} h1='Isabelle Paris' h2='Voice and more' h3='Stimm- und Sprechschulung' />
          {/* <Portrait src={marco} h1='Marco Passardi' h2='Finanzexperte an der HLU' h3='Bilanzen lesen' /> */}
          <Portrait src={ulrike} h1='Ulrike Posche' h2='Stern' h3='Porträt' />
          <Portrait src={constantin} h1='Constantin Seibt' h2='Autor Republik' h3='Storytelling' />
          <Portrait src={steiner} h1='Nicola Steiner' h2='Literaturclub SRF' h3='Literatur' />
          <Portrait src={julian} h1='Julian Schmidli' h2='Data-Journalist SRF' h3='Data Driven Journalism' />
          <Portrait src={schmidt} h1='Elisabeth Schmidt' h2='Textchefin' h3='Soft Skills' />
          <Portrait src={matthias} h1='Matthias Schwaibold' h2='Medienanwalt' h3='Medienrecht' />
          <div className='clearfix' />
        </div>
      </React.Fragment>

    );
  }
}

export default Schulklasse;