import React from 'react';

import Gallery from '../../comps/gallery';

//Gallery
import gr_pauline from './media/gastredaktionen/pauline.jpg';
import gr_simona from './media/gastredaktionen/simona.jpg';
import gr_andrea from './media/gastredaktionen/andrea.jpg';
import gr_florian from './media/gastredaktionen/florian.jpg';
import gr_remo from './media/gastredaktionen/remo.jpg';
import gr_silvana from './media/gastredaktionen/silvana.jpg';
// import gr_forster from './media/gastredaktionen/forster.jpg';

class Gastredaktionen extends React.Component {
  render()
  {
    return (
      <Gallery className='fullwidthgallery'>
        <div>
          <img src={gr_pauline} alt='Pauline Broccard'/>
          <span>
            <b>Pauline Broccard</b>, Schweizer-Illustrierte<br />
            Gastredaktion: Pulse Nairobi & Flow Hamburg
          </span>
        </div>

        {/* <div>
          <img src={gr_pauline} alt='Marco Brunner'/>
          <span>
            <b>Marco Brunner</b>, Handelszeitung<br />
            Gastredaktion: Le Temps Lausanne & timeline.com San Francisco 
          </span>
        </div>

        <div>
          <img src={gr_pauline} alt='Anaïs Decasper'/>
          <span>
            <b>Anaïs Decasper</b>, Energy<br />
            Gastredaktion: Icon Berlin & Glanz & Gloria SRF
          </span>
        </div> */}

        <div>
          <img src={gr_silvana} alt='Silvana Degonda'/>
          <span>
            <b>Silvana Degonda</b>, Schweizer-Illustrierte<br />
            Gastredaktion: Spiegel Hamburg & Rundschau SRF
          </span>
        </div>

        {/* <div>
          <img src={gr_pauline} alt='Anina Frischknecht'/>
          <span>
            <b>Anina Frischknecht</b>, Beobachter<br />
            Gastredaktion: St. Galler Tagblatt & Rundschau SRF
          </span>
        </div> */}

        <div>
          <img src={gr_andrea} alt='Andrea Germann'/>
          <span>
            <b>Andrea Germann</b>, Glückspost<br />
            Gastredaktion: TeleZüri & Kassensturz SRF
          </span>
        </div>

        {/* <div>
          <img src={gr_pauline} alt='Andreas Hobi'/>
          <span>
            <b>Andreas Hobi</b>, BLICK-Newsroom/Sport<br />
            Gastredaktion: Energy Zürich & Capetown Magazin
          </span>
        </div> */}

        <div>
          <img src={gr_simona} alt='Simona Boscardin'/>
          <span>
            <b>Simona Boscardin</b>, BLICK-Newsroom/News<br />
            Gastredaktion: VICE Berlin & Zeit Online Berlin
          </span>
        </div>

        {/* <div>
          <img src={gr_forster} alt='Sven Forster'/>
          <span>
            <b>Sven Forster</b>, BLICK-Newsroom/News<br />
            Gastredaktion: Münchner Abendzeitung & Auslandbüro ZDR New York
          </span>
        </div> */}

        <div>
          <img src={gr_remo} alt='Remo Bernet'/>
          <span>
            <b>Remo Bernet</b>, BLICK-Newsroom/People<br />
            Gastredaktion: Newslab SRF & UFA LAB Berlin
          </span>
        </div>

        {/* <div>
          <img src={gr_pauline} alt='Remo Schraner'/>
          <span>
            <b>Remo Schraner</b>, Magazin SonntagsBlick<br />
            Gastredaktion: Einstein SRF
          </span>
        </div> */}

        <div>
          <img src={gr_florian} alt='Florian Wicki'/>
          <span>
            <b>Florian Wicki</b>, BLICK-Newsroom/Politik<br />
            Gastredaktion: Politico Brüssel & Republik Zürich
          </span>
        </div>

        {/* <div>
          <img src={gr_pauline} alt='Carlo Lardi'/>
          <span>
            <b>Carlo Lardi</b>, BLICK-Newsroom/WebTV<br />
            Gastredaktion: Energy Zürich & izzymag.ch, Zürich
          </span>
        </div>

        <div>
          <img src={gr_pauline} alt='Rahel Zingg'/>
          <span>
            <b>Rahel Zingg</b>, Style<br />
            Gastredaktion: Refinery German Berlin & WELT.de Berlin
          </span>
        </div>

        <div>
          <img src={gr_pauline} alt='Anja Zobrist'/>
          <span>
            <b>Anja Zobrist</b>, Energy<br />
            Gastredaktion: BLICK WebTV & Tagespiegel Berlin 
          </span>
        </div> */}

        {/* <div>
          <img src={gr_pauline} alt='Noah Zygmont'/>
          <span>
            <b>Noah Zygmont</b>, Energy<br />
            Gastredaktion: ECO SRF & BILD Berlin
          </span>
        </div> */}

      </Gallery>

    );
  }
}

export default Gastredaktionen;