import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';

import logo from './ringier_logo.png';

class Footer extends React.Component {
  render()
  {
    return (
      <footer>
        <div id='smicons'>
          <p>
            <a href="https://twitter.com/RingierJouschu" target='_blank' rel="noopener noreferrer" className="fa fa-twitter"> </a>
            <a href="https://www.facebook.com/RingierJournalistenschule/" target='_blank' rel="noopener noreferrer" className="fa fa-facebook"> </a>
            <a href="https://www.youtube.com/channel/UCCzi7pOjP-n8NUYxgyVlsFA" target='_blank' rel="noopener noreferrer" className="fa fa-youtube"> </a>
            <a href="https://www.instagram.com/ringierjournalistenschule" target='_blank' rel="noopener noreferrer" className="fa fa-instagram"> </a>
          </p>
        </div>
        <div id='footercenter'>
          <p>
            <Link to='/kontakt'>Kontakt</Link>
            <Link to='/impressum'>Impressum</Link>
            <a href='https://www.ringier.ch/de/datenschutzbestimmungen' target='_blank' rel="noopener noreferrer">Datenschutz</a>
          </p>
        </div>
        <img src={logo} id='footerlogo' alt='Logo Ringier' />
      </footer>
    );
  }
}

export default Footer;
