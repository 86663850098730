import React from "react";

import "./index.scss";

import StdPage from "../../comps/stdpage";
import SubPage from "../../comps/subpage";
import Content from "../../comps/content";

//Fotos
import header from "./media/header.jpg";

class Bewerbung extends React.Component {
  render() {
    return (
      <StdPage title="Bewerbung" header={header} focusPoint="84% 50%">
        <SubPage naviTitle="Talent" className="bottomline">
          <Content>
            <h3>Berufswahl Journalismus</h3>
            <h2>#Talent #Herz #Hirn</h2>
            <p>
              Siehst du deine Zukunft in den Medien? Die Ringier
              Journalistenschule bietet dir eine erstklassige Ausbildung bei
              Ringier, Ringier Medien Schweiz oder Energy.
            </p>
            <p>
              Die Klasse 2022/2024 ist voll. Der nächste Jahrgang beginnt am
              18. August 2025. Hier findest du Informationen über die Bewerbung
              ab <b>November 2024</b>.
            </p>
          </Content>
        </SubPage>

        <SubPage naviTitle="Du bringst" className="bottomline">
          <Content>
            <h2>Das erwarten wir</h2>
            <ul>
              <li>Du bist neugierig, agil und digital fit.</li>
              <li>Du hast tausend Ideen und einige schon umgesetzt.</li>
              <li>
                Du bringst eine abgeschlossene Berufsausbildung oder einen
                Bachelor-Abschluss mit.
              </li>
              <li>
                Du siehst deine berufliche Zukunft bei Ringier, Ringier Medien Schweiz oder Energy.
              </li>
            </ul>
          </Content>
        </SubPage>

        <SubPage naviTitle="Wir bringen" className="bottomline">
          <Content>
            <h2>Das bieten wir</h2>
            <ul>
              <li>Du lernst alles, was Journalismus ausmacht.</li>
              <li>
                Auf den Redaktionen in Zürich machst du die praktische
                Ausbildung.
              </li>
              <li>
                Du bringst Ideen ein, recherchierst, schreibst Artikel für Print
                und Online und machst eigene Video- oder Audio-Beiträge.
              </li>
              <li>Im Schulunterricht in Zofingen übst du mit den Besten.</li>
            </ul>
          </Content>
        </SubPage>

        {/*           <SubPage naviTitle='Bewerbung' className='bottomline' padding='small'>
            <ReadMore  hideBorders={true}>
              <Content>

                <h2>Bewirb dich</h2>
                <ul>
                  <li>Schreibe eine Reportage zum Thema Pandemie (4000 Zeichen) und drehe dazu ein Video mit dem Smartphone (1-3 Minuten). </li>
                  <li>Schicke uns dein Motivationsschreiben, CV, Zeugnisse, Diplome und allfällige Arbeitsproben.</li>
                  <li>Wähle deine Wunschredaktion: Beobachter, Bilanz, BLICK, Handelszeitung, Energy, Schweizer Illustrierte.</li>
                </ul>
                <p><a href="https://jobs.ringier.ch/offene-stellen/volontaer-in-ringier-journalistenschule/618671ad-2758-4b60-8d45-f3cc4c830fdc" target="_blank" rel="noopener noreferrer">Bewirb dich jetzt</a></p>

              </Content>
            </ReadMore>
          </SubPage> */}
      </StdPage>
    );
  }
}

export default Bewerbung;
