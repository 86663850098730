import React from 'react';
import { Link } from 'react-router-dom'

import './index.scss';

import StdPage from '../../comps/stdpage';
import SubPage from '../../comps/subpage'
import Content from '../../comps/content';
import Image from '../../comps/image'
import Gallery from '../../comps/gallery';
import ReadMore from '../../comps/readmore';

//Fotos
import header from './media/header.jpg';
import schulleiter from './media/schulleiter.jpg';
import frankameyer from './media/frankameyer.jpg';
import sammelband from './media/sammelband.jpg';
import firstclassroom from './media/firstclassroom.jpg';
import villa from './../villa/media/villa.jpg'
import oswald from './media/oswald.png';

//Gallery Jubiläum
import g_1 from './media/jubilaeum/1.jpg';
import g_2 from './media/jubilaeum/2.jpg';
import g_3 from './media/jubilaeum/3.jpg';
import g_4 from './media/jubilaeum/4.jpg';
import g_5 from './media/jubilaeum/5.jpg';

class Geschichte extends React.Component {
  render()
  {
    return (
        <StdPage title='Geschichte' header={header}>

          <Content>
            
          </Content>

          <SubPage naviTitle='Geschichte' className='bottomline'>
            <Content>
              <h3>Geschichte</h3>
              <h2>Eine Pioniertat</h2>

              <p>1974 war die Gründung der Ringier Journalistenschule (in der Branche liebevoll Jouschu genannt) eine mutige und überraschende Ausbildungsoffensive des Verlegers Hans Ringier und seines Direktionspräsidenten Heinrich Oswald.</p>

              <h4>Hans Ringier, Verleger</h4>
              <p>Hans Ringier zögerte. Mehr noch: Er musste mit sich ringen. Schon eine ganze Weile hatte sich der Patron mit dem Gedanken getragen, eine Journalistenschule zu gründen. Die Schule musste sein, da war er sich inzwischen völlig sicher. Er wusste auch, wo er sie haben wollte. So eine Institution brauchte selbstverständlich einen angemessenen Rahmen, einen würdigen Sitz – immerhin war eine Ausbildung für angehende Journalisten in den 70-er Jahren des letzten Jahrhunderts für Schweizer Verhältnisse eine Pioniertat. Hans Ringier spielte mit dem Gedanken, sein Elternhaus dafür zur Verfügung zu stellen, die «Römerhalde».</p>
  
              <p>
                Doch das Haus der eigenen Eltern? In dem er selber aufgewachsen war? Dieser Ort schien ihm dann doch fast zu intim. 
              </p>
            </Content>


            <Image src={villa} className='fullwidth' />

            <Content>
              <h4>Das Elternhaus von Hans Ringier - die Villa Römerhalde in Zofingen</h4>
              <p>Schliesslich aber begrüsste Hans Ringier die Gäste anlässlich der Einweihung 1974 mit den Worten: «Ich gestehe Ihnen deshalb offen, dass ich zunächst nicht ganz leichten Herzens die Türen dieses Hauses geöffnet habe, das für mich bis zuletzt so etwas wie ein glückliches Réduit in der hektischen Geschäftswelt war.»</p>

              <p>Die geladenen Gäste waren gerührt. Schüler, Dozenten und Schulleiter sind ihrem Gründer Hans Ringier seit fünfzig Jahren dankbar, dass er ihnen sein Elternhaus überlassen hat. Die Villa Römerhalde hoch über Zofingen ist ein stiller Ort – wie geschaffen für die  hochkonzentrierte Weitergabe von theoretischem und praktischem Wissen, für die Aufnahme und Verarbeitung des Rüstzeugs, das Journalisten nun einmal brauchen, wenn sie ihren Beruf dauerhaft erfolgreich ausüben wollen. </p>

              <Image src={firstclassroom} caption='Das erste Schulzimmer' className='inline' />

              <h4>Heinrich Oswald</h4>
              <p>Vater der Idee einer Ringier-Journalistenschule war Heinrich Oswald, zu jener Zeit Delegierter des Verwaltungsrates und Direktionspräsident des Verlags. Nach Abschluss des ersten zweijährigen Lehrgangs dankte er den frisch Diplomierten für ihren Lerneffort: «Wo Grosses geleistet wird, ist es bekanntlich die Frucht aus dem Wechselspiel von Lehre und Lernen sowie das Ergebnis von Fleiss und Selbstdisziplin. Auch für sogenannte Naturtalente gibt es keine berufliche Höchstleistung allein durch empirisches Pröbeln.» </p>
              <img src={oswald} alt='Heinrich Oswald' className='portraitround'/>
              <p>Nicht alle applaudierten der Bildungsoffensive des Verlagshauses Ringier . Die Berufsverbände hatten jahrelang ähnliche Projekte gewälzt, sahen sich plötzlich vor vollendete Tatsachen gestellt und reagierten pikiert. </p>

              <h4>Stiftung Hans Ringier</h4>
              <p>Am 15. Mai 1998 liess Hans Ringier seine Schule unter das Dach der neugegründeten Stiftung Hans Ringier überführen.</p>

              <p>Stiftungszweck: «Förderung und Entwicklung des Schweizer Journalismus, insbesondere durch die theoretische und praktische Schulung, Aus- und Weiterbildung von Journalistinnen und Journalisten. Dabei sollen Professionalität, Ethik, Handwerk und Verantwortung erste Priorität bekommen.»</p>

              <p>Hans Ringier berief folgende Persönlichkeiten als erste Stiftungsräte:</p>
              <ul>
                <li><b>Edouard Brunner</b>, Diplomat, Eysins</li>
                <li><b>Dieter Wild</b>, «Spiegel»-Urgestein, Hamburg</li>
                <li><b>Iso Camartin</b>, Publizist, Zürich</li>
                <li><b>Frank A. Meyer</b>, Ringier Publizist, Kilchberg</li>
                <li><b>Martin Werfeli</b>, Leiter der Konzernbereichs Finanzen Ringier, Brittnau</li>
              </ul>

              <h4>Frank A. Meyer, Präsident Stiftung Hans Ringier</h4>
 
              <p>
                An der konstituierenden Stiftungsratssitzung vom 23. Mai 1998 wurden Frank A. Meyer zum Präsidenten und Martin Werfeli zum Vizepräsidenten der Stiftung gewählt. 
              </p>
            </Content>
          </SubPage>

          <SubPage naviTitle='Die Schulleiter'>
            <Content>
              <h2>Die Schulleiter</h2>
              Seit der Gründung haben sieben Personen die Journalistenschule geleitet.
              <ul>
                <li>Werner Meier, 1974-1978</li>
                <li>Franz C. Widmer, 1979-1986</li>
                <li>Manuel Isler, 1987-1998 </li>
                <li>Fridolin Luchsinger, 1998-2009</li>
                <li>Jürg Lehmann, 2009-2010</li>
                <li>Hannes Britschgi, 2011-2022</li>
                <li>Peter Hossli, 2022-</li>
              </ul>
            </Content>

          </SubPage>

          <Image src={schulleiter} caption='Hannes Britschgi, Jürg Lehmann, Franz C. Widmer, Fridolin Luchsinger' className='fullwidth' />

          <SubPage naviTitle='40 Jahre Journalistenschule'>
            <Content>
              <h2>Das 40-Jahre-Jubiläum</h2>

              <p>Am 8. Dezember 2014 feierte Ringier das 40-Jahre-Jubiläum seiner Journalistenschule mit einem rauschenden Fest im Pressehaus an der Dufourstrasse in Zürich.</p>
              <p>Über 300 Gäste erwiesen der Schule die Ehre. Die Verlegerfamilie durfte ein grosses Dankeschön für ihr ideelles und privat finanziertes Engagement entgegennehmen.</p>

              <p>Die Jubiläumsrede hielt Frank A. Meyer, Stiftungsratspräsident: «Freiheit ist Fragen»:</p>
              <Gallery className='inlinegallery'>
                <div><img src={g_1} alt='CEO Marc Walder'/><span>Ringier CEO Marc Walder präsentiert am Jubiläumsfest den Sammelband «40 Jahre Ringier Journalistenschule».</span></div>
                <div><img src={g_2} alt='Verleger Michael Ringier'/><span>Verleger Michael Ringier mit Ehegattin Ellen Ringier, Schwester Annette Ringier und CEO Marc Walder.</span></div>
                <div><img src={g_3} alt='Ueli Schmezer'/><span>Als ehemaliger Ringier Journalistenschüler wurde auch Kassensturzmoderator Ueli Schmezer im Sammelband porträtiert.</span></div>
                <div><img src={g_4} alt='Frank A. Meyer'/><span>Marc Walder zeigt Stiftungsrats-Präsident Frank A. Meyer das Jubiläumnsbuch.</span></div>
                <div><img src={g_5} alt='Roger Benoit'/><span>Verleger Michael Ringier und die Formel-1-Legende Roger Benoit.</span></div>
            </Gallery>
            </Content>
          </SubPage>

          <Image src={frankameyer} alt='Frank A. Meyer' className='fullwidth'/>

          <ReadMore>
            <h3>Frank A. Meier</h3>
            <h2>«Freiheit ist Fragen»</h2>
            <p style={{fontStyle: 'italic'}}>
              Worüber wird geredet, wenn heute von Journalismus und Journalisten geredet wird? Über die Kompetenz, sich in der digitalen Medienwelt zurechtzufinden, sich den neuen, technisch bedingten Voraussetzungen anzupassen. Es geht darum, polyvalent zu sein, was bedeutet, den Anforderungen von Facebook, Instagram und Twitter zu genügen. Das Handwerk, das ja auf die Sprache hinausläuft, ist der zunehmenden Beschleunigung anzupassen.
            </p>
            <Link to='/rede'>Die ganze <b>Rede</b> lesen</Link>
          </ReadMore>

          <Image src={sammelband} alt='Sammelband' className='fullwidth'/>

          <SubPage naviTitle='Sammelband'>
            <Content>
              <h2>Sammelband</h2>
              Zur Feier legte die Schule den Sammelband «40 Jahre Ringier Journalistenschule» auf. Die siebzehn Absolventinnen und Absolventen des Lehrgangs 2013-2015 porträtierten je eine Alumna oder einen Alumnus (Ehemalige). So sind folgende Porträts im Sammelband vereint:
              <ul>
                <li><b>Stefan Barmettler</b>, Chefredaktor Handelszeitung</li>
                <li><b>Martin Beglinger</b>, NZZ-Autor</li>
                <li><b>Silvia Binggeli</b>, Chefredaktorin annabelle</li>
                <li><b>Christian Dorer</b>, Chefredaktor BLICK-Gruppe</li>
                <li><b>Rita Flubacher</b>, Reporterin Tages-Anzeiger</li>
                <li><b>Susanne Hochuli</b>, Regierungsrätin im Kanton Aargau</li>
                <li><b>Fredy Hämmerli</b>, VRP Internetagentur Swisscontent</li>
                <li><b>Philipp Löpfe</b>, Wirtschafspublizist watson</li>
                <li><b>Norbert Neininger</b>, Verleger und Chefredaktor Schaffhauser Nachrichten</li>
                <li><b>Yolanda Risi-Egger</b>, Immobilien Marketing</li>
                <li><b>Martin Schläpfer</b>, Leiter Direktion Wirtschaftspolitik Migros</li>
                <li><b>Ueli Schmezer</b>, Moderator Kassensturz SRF</li>
                <li><b>Peer Teuwsen</b>, Leiter publizistische Projekte, NZZ</li>
                <li><b>Thomas Trüeb</b>, Berater Ringier</li>
                <li><b>Mirjam von Arx</b>, Filmproduzentin ican films gmbh</li>
                <li><b>Marc Walder</b>, CEO Ringier AG</li>
                <li><b>Christoph Zürcher</b>, Ressortleiter Gesellschaft und Stil NZZ am Sonntag</li>
              </ul>
            </Content>
          </SubPage>



      </StdPage>
    );
  }
}

export default Geschichte;