import React from 'react';

import './schulklasse.scss';

// Aktuelle Klasse
import abt from './media/students/abt.jpg';
import baeni from './media/students/baeni.jpg';
import belgeri from './media/students/belgeri.jpg';
import horni from './media/students/horni.jpg';
import huesser from './media/students/huesser.jpg';
import leuenberger from './media/students/leuenberger.jpg';
import madonna from './media/students/madonna.jpg';
import nyfeler from './media/students/nyfeler.jpg';
import oetiker from './media/students/oetiker.jpg';
import schaeublin from './media/students/schaeublin.jpg';
import schaffner from './media/students/schaffner.jpg';
import vetiger from './media/students/vetiger.jpg';
import walder from './media/students/walder.jpg';

function Portrait(props)
{
  return (
    <div className='portrait'>
      <div className='image'>
        <img src={props.src} alt={props.h1} />
      </div>
      <div className='text'>
        <p className='h1'>{props.h1}</p>
        <p className='h2'>{props.h2}</p>
      </div>
    </div>
  )
}

class Schulklasse extends React.Component {
  render()
  {
    return (
      <React.Fragment>
        <div className='schulklasse'>
          <Portrait src={abt} h1='Nicola Abt' h2='Blick-Newsroom' />
          <Portrait src={baeni} h1='Robin Bäni' h2='Blick-Newsroom' />
          <Portrait src={belgeri} h1='Sara Belgeri' h2='Blick-Newsroom' />
          <Portrait src={horni} h1='Nicolas Horni' h2='Blick-Newsroom' />
          <Portrait src={huesser} h1='Noemi Hüsser' h2='Beobachter' />
          <Portrait src={leuenberger} h1='Janik Leuenberger' h2='Blick-Newsroom' />
          <Portrait src={madonna} h1='Lena Madonna' h2='Handelszeitung' />
          <Portrait src={nyfeler} h1='Vanessa Nyfeler' h2='Schweizer Illustrierte' />
          <Portrait src={oetiker} h1='Lea Oetiker' h2='Beobachter' />
          <Portrait src={schaeublin} h1='Anja Schäublin' h2='Radio Energy' />
          <Portrait src={schaffner} h1='Joschka Schaffner' h2='Blick-Newsroom' />
          <Portrait src={vetiger} h1='Yara Vettiger' h2='Schweizer Illustrierte' />
          <Portrait src={walder} h1='Samuel Walder' h2='Blick-Newsroom'/>
          <div className='clearfix' />
        </div>
        {/* <div className='schulklasse center'>
          <div className='spacer'></div>
          <div className='clearfix' />
        </div> */}
      </React.Fragment>

    );
  }
}

export default Schulklasse;