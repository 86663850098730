import React from 'react';
import './index.scss';
import Footer from '../../comps/footer';
import Navigation from '../../comps/navigation'
import SubPage from '../../comps/subpage';
import Helpers from '../../helpers.js';
import {YoutubeController, YoutubeControllerIcon} from '../youtubecontroller';

var scrollToElement = require('scroll-to-element');

class StdPage extends React.Component {

  state = {
    subNaviSize: '',
    activeSubNaviItem: '',
    activateFullscreen: false
  }
  naviPositionArray = [];

  componentDidMount()
  {
    //Add SubNavi-Click-And-Scroll-Event. Do not use forEach! (IE)
    const selects = document.querySelectorAll('.subNaviLink');
    for(var i = 0; i < selects.length; i++)
    {
      const l = selects[i]
      l.addEventListener("click", e => {
        scrollToElement(`#${e.target.getAttribute("data-anchor")}`, {
          offset: -50,
        });
      })
    }

    //Observe scrolling
    window.addEventListener('scroll', this.onScroll);

    //Observe resize
    window.addEventListener('resize', this.onResize);

    //Build Position Array
    this.buildNaviPositionArray();

    //Scroll to top
    window.scrollTo(0, 0);
  }

  componentWillUnmount()
  {
    //Remove Listeners
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
  }

  onScroll = () => {
    //Get Scrolltop (edge Workaround)
    var scrollTop = 0;
    if (window.pageYOffset !== undefined)
      scrollTop = window.pageYOffset;
    else 
    {
      var d = document, r = d.documentElement,b = d.body;
      scrollTop = r.scrollTop || b.scrollTop || 0;
    }

    const naviTop = document.querySelector('header').clientHeight - 50;

    //Observe Sub-Navi-Fixation
    if(scrollTop >= naviTop && this.state.subNaviSize !== 'fixed')
    {
      this.setState({'subNaviSize': 'fixed'})
    }
    else if(scrollTop < naviTop && this.state.subNaviSize === 'fixed')
    {
      this.setState({'subNaviSize': ''})
    }

    //Observe Sub-Navi-Active Link
    if(this.naviPositionArray.length > 0)
    {
      //If scrollpos is at bottom, highlight last navigation link, no matter what.
      const docHeight = Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
      if(scrollTop + window.innerHeight >= docHeight - 100)
      {
        this.setState({'activeSubNaviItem': this.naviPositionArray[this.naviPositionArray.length - 1].anchor})
      }
      else
      {
        for(var i = this.naviPositionArray.length - 1; i >= 0; i--)
        {
          if(scrollTop + 50 >= this.naviPositionArray[i].top)
          {
            if(this.naviPositionArray[i].anchor !== this.state.activeSubNaviItem)
            {
              this.setState({'activeSubNaviItem': this.naviPositionArray[i].anchor})
            }
            break;
          }
        }
      }
    }
  }

  onResize = () => {
    this.buildNaviPositionArray();
  }

  buildNaviPositionArray()
  {
    this.naviPositionArray = [];
    document.querySelectorAll('#stdpage .subpage').forEach(dom => {

      //Calcuate Offset position
      var bodyRect = document.body.getBoundingClientRect();
      var elemRect = dom.getBoundingClientRect();
      var offset = elemRect.top - bodyRect.top;

      this.naviPositionArray.push({
        'anchor': dom.getAttribute('id'),
        'top': offset - 50
      })
    });
  }

  render()
  {
    //Build Position Array
    this.buildNaviPositionArray();

    //Create Navi by finding "SubPage"
    const createNavItem = c => {
      if(c.type === SubPage)
      {
        var key = Helpers.createKeyFromString(c.props.naviTitle);
        return <span className={`subNaviLink ${this.state.activeSubNaviItem === key ? 'active' : ''}`} key={key} data-anchor={key}>{c.props.naviTitle}</span>
      }
      return null;
    }

    const subNavi = Array.isArray(this.props.children) ? this.props.children.map((c, i) => createNavItem(c)) : null;

    return (
      <div id='stdpage' className={this.props.className ? this.props.className : ''}>
        {/* Fullscreen Video on click */}
        {this.props.youtubeid &&
          <YoutubeController videoid={this.props.youtubeid} activate={this.state.activateFullscreen} onClose={() => this.setState({activateFullscreen: false})}/>
        }

        {/* Header with Navigation and Logo */}
        {/* If Image Header */}
        {this.props.header &&
          <header className='image' style={{backgroundImage: `url(${this.props.header})`, backgroundPosition: this.props.focusPoint ? this.props.focusPoint : 'center center'}}>
            <Navigation />
            
            {/* Page-Title */}
            <div id="pagetitlewrappermobile">
              <div id="pagetitle"><span>{this.props.title}</span></div>
            </div>
          </header>
        }

        {/* If Video */}
        {this.props.video &&
          <header className='video'>
            <Navigation />

            <video id='fullscreenvideo' width='100%' height='100%' autoPlay muted autobuffer="true" loop playsInline={true} poster={this.props.poster}>
              <source src={this.props.video} type="video/mp4" />
            </video>

            {/* Controller */}
            {this.props.youtubeid &&
              <YoutubeControllerIcon onClick={() => this.setState({activateFullscreen: true})} />
            }
            
          </header>
        }

        {/* Page Navigation */}
        <div id='pagenavi' className={this.state.subNaviSize === 'fixed' ? 'navifixed' : ''}>

          {/* Page-Title */}
          <div id="pagetitlewrapper">
            <div id="pagetitle"><span>{this.props.title}</span></div>
          </div>

          {/* Page-Navigation */}
          <div id='navigation'>
            <div id='subnavi'>
              {subNavi}
            </div>
          </div>

        </div>

        {/* Add Spacer after fixe navi. Otherwise text jumps. But only on desktop! */}
        <div id='pagenavispacer' style={{'display': this.state.subNaviSize === 'fixed' ? 'block' : 'none'}}></div>

        {/* Actual Content */}
        {this.props.children}

        {/* Footer */}
        <Footer />
      </div>

    )
  }
}

export default StdPage;
