import React from 'react';
import { Link } from 'react-router-dom'

import './index.scss';

import Schulklasse from './schulklasse.js';
import Dozenten from './dozenten.js';

import StdPage from '../../comps/stdpage';
import SubPage from '../../comps/subpage'
import Content from '../../comps/content';
import Image from '../../comps/image'
import Gallery from '../../comps/gallery';
import ReadMore from '../../comps/readmore';
import Outline from '../../comps/outline';
import Youtube from '../../comps/youtube';
// import CollapseText from '../../comps/collapsetext';

//Fotos
import header from './media/header.mp4';
import poster from './media/poster.jpg';
import villa from '../villa/media/villa.jpg';
import geschichte from './media/geschichte.jpg';

//Klassen
import k_2016 from './media/klassen/2016.jpg';
import k_2013 from './media/klassen/2013.jpg';
import k_2011 from './media/klassen/2011.jpg';
import k_2003 from './media/klassen/2003.jpg';
import k_1999 from './media/klassen/1999.jpg';
import k_1993 from './media/klassen/1993.jpg';
import k_2019 from './media/klassen/2019.jpg';
import k_2022 from './media/klassen/2022.jpg';

class Schule extends React.Component {
  render()
  {
    return (
        <StdPage title='Schule' video={header} poster={poster}>

          <SubPage naviTitle='Leitbild' className='bottomline'>
            <Content>
              <h3>Leitbild</h3>
              <h2>Lernen im journalistischen Alltag</h2>
              <p>
                Was <b>Verleger Michael Ringier</b> auch im Zeitalter der Digitalisierung noch gerne druckt: «handwerklich und stilistisch hochstehenden Journalismus, der der Wahrheit verpflichtet ist».
              </p>

              <p>
                Die <b>Ringier Journalistenschule</b> leistet auf rein privatwirtschaftlicher Basis seit über 50 Jahren ihren Beitrag zur «Förderung und Entwicklung des Schweizer Journalismus».
              </p>

              <p>
                Die Ausbildung ermächtigt junge Talente, die Anforderungen des modernen Journalismus zu meistern. Sie lernen das <b>klassische</b> und das <b>digitale Handwerk</b>. So können sie liefern, was im Journalismus gefragt ist: Fakten und Zusammenhänge, Anregung und Unterhaltung.
              </p>

              <p>
                Das zweijährige Ausbildungs-Volontariat ist ganz der <b>journalistischen Praxis</b> verpflichtet. Die Lernenden erhalten die praktische Ausbildung im Arbeitsalltag auf den Redaktionen und die schulische im Klassenzimmer. Dieses duale Konzept orientiert sich am Erfolgsmodell der schweizerischen Berufslehre.
              </p>

              <Youtube videoid='ciNcn6T8JLQ' />
            </Content>
          </SubPage>

          <Image src={k_2022} caption="Foto: Stefan Bohrer" alt='Die aktuelle Schulklasse' className='fullwidth'/>

          <SubPage naviTitle='Lernende' className='bottomline'>
            <Content>
              <h3>Lernende</h3>
              <h2>Die Klasse 2022/2024</h2>

            </Content>

            <Schulklasse />

          </SubPage>

          <Outline>
            <Content>
              <h3>Nachgefragt</h3>
              <h2>Q&A mit dem Jahrgang 2022 / 2024</h2>
              <Youtube videoid='r0oU1DE--zw' />
            </Content>
          </Outline>

          <SubPage naviTitle='Lehrende' className='bottomline'>
            <Content>
              <h3>Lehrende</h3>
              <h2>Praktiker im Schulzimmer</h2>
            </Content>

            <Content>
              <p>
                An der Ringier Journalistenschule gilt die Losung: <b>Von den Besten lernen.</b> Die Dozentinnen und Dozenten kommen aus dem Berufsalltag. Sie bringen ihre Erfahrungen ins Klassenzimmer und vermitteln Wissen und Handwerk vor allem mit praktischen Übungen. Die einen unterrichten tageweise, andere bestreiten eine ganze Woche. Im Laufe der zweijährigen Ausbildung arbeiten über vierzig Journalistinnen und Journalisten mit der Klasse. 
              </p>

              <p>
                Unter den Besten ihrer Disziplin sind Ringier-interne Kolleginnen und Kollegen, auch externe von anderen Schweizer Verlagshäusern und aus dem deutschsprachigen Europa. Eine kleine Auswahl:
              </p>
            </Content>

            <Dozenten />
        </SubPage>

       <SubPage naviTitle='Ehemalige'>
          <ReadMore hideBorders={true}>
            <h3>Alumni</h3>
            <h2>Ehemalige</h2>
            <p>
              Zu den Absolventen der <b>Ringier Journalistenschule</b> zählen Journalistinnen und Journalisten wie Marc Walder (CEO Ringier-Gruppe), Arthur Honegger (10vor10-Moderator SRF),  Christian Dorer (Chefredaktor Blick-Gruppe), Martin Beglinger (NZZ-Autor), Rita Flubacher (Wirtschaftsjournalistin Tages-Anzeiger), Peer Teuwsen (NZZ-Autor) oder Ueli Schmezer (Kassensturz-Moderator SRF).
            </p>

            <Gallery className='inlinegallery'>
              <div><img src={k_2016} alt='Jahrgang 2016-2018'/><span><b>Jahrgang 2016-2018</b><br />Florian Wicki, Carlo Lardi, Remo Bernet, Marco Brunner, Andrea Germann, Andreas Hobi, Anina Frischknecht, Simona Boscardin, Anaïs Decasper, Pauline Broccard, Rahel Zingg, Anja Zobrist, Remo Schraner, Sven Forster, Noah Zygmont, Silvana Degonda</span></div>
              <div><img src={k_2013} alt='Jahrgang 2013-2015'/><span><b>Jahrgang 2013-2015</b><br />Michèle Graf-Kaiser, Linda Leitner, Claudia Spinnler, Viviane Bischoff, Charlotte Fischli, Simon Marti, Sarah Meier, Vanja Kadic, Céline Krapf, Manuela Enggist, Vinzenz Greiner, Kim Schärer, Thierry Debieux, Céline Brunner, Leonie Studer, Joel Grolimund, Michael Bolzli</span></div>
              <div><img src={k_2011} alt='Jahrgang 2011-2013'/><span><b>Jahrgang 2011-2013</b><br />Leila Schäfli, Eva Bünter, Christof Vuille, Stephanie Renner, Simon Keller, Gita Topiwala, Frank Estermann, Jill Zimmerli, Stephan Gutknecht, Alexandra Fitz, Biljana Jovic, Anne-Florence Pasquier, Kevin Gertsch, Esther Meyer, Ramona Schweizer, Alex Dutler, Livia Vonaesch</span></div>
              <div><img src={k_2003} alt='Jahrgang 2003-2005'/><span><b>Jahrgang 2003-2005</b><br />Philip Gehri, Monika Freund, Rémy Kappeler, Markus Ehinger, Juliette Schild-Leuthold, Caspar Schärer, Reto Gustin, Adrian Heuss, Christine Aschwanden, Anja Blankenhorn, Christian Lindner, Andreas Böni, Christian Roth</span></div>
              <div><img src={k_1999} alt='Jahrgang 1999-2001'/><span><b>Jahrgang 1999-2001</b><br />Simon Bühler, Sabine Eva Wittwer, Thomas Senn, David Becker, Nina Merli, Matthias Plüss, Marianne Kägi, Michael Sahli, Gion-Duri Vincenz, Fridolin Luchsinger, Thomas Moll, Tuuli Stalder, Mirjam Oertli</span></div>
              <div><img src={k_1993} alt='Jahrgang 1993-1995'/><span><b>Jahrgang 1993-1995</b><br />Cigdem Ruf, Andreas Güntert, Philippe Schmuki, Karin Huser, Patrick Teuscher, Claudia von Wartburg, Marc Walder, Peter Balzli, Gregor Sonderegger, Regula Blaser-Huber, Daniel Arnet, Daniela Fabian, Christian Dütschler</span></div>
            </Gallery>
            <Link to='/alumni'>Alle <b>Jahrgänge</b> entdecken</Link>
          </ReadMore>
        </SubPage>

        <Image src={villa} alt='Die Villa von vorne' className='fullwidth'/>

        <SubPage naviTitle='Villa' padding='small'>
          <ReadMore>
            <h3>Villa Römerhalde</h3>
            <h2>Schule im Park</h2>
            <p>
              Die Villa Römerhalde in Zofingen bietet alles, was stille Konzentration fördert: Licht, Luft und Lebensraum.
              1974 durfte die neugegründete Ringier Journalistenschule dieses Wohnhaus der Familie Ringier als Lernstätte übernehmen. 
            </p>
            <Link to='/villa'>Mehr über das <b>«Schulhaus»</b> erfahren</Link>
          </ReadMore>
        </SubPage>

        <Image src={geschichte} alt='Geschichte der Ringier Journalistenschule' className='fullwidth'/>

        <SubPage naviTitle='Geschichte' padding='small'>
          <ReadMore hideBorders={true}>
            <h3>Eine Pioniertat</h3>
            <h2>Die Ringier Journalistenschule</h2>
            <p>
              1974 war die Gründung der Ringier Journalistenschule (in der Branche liebevoll Jouschu genannt) eine mutige und überraschende Ausbildungsoffensive des Verlegers Hans Ringier und seines Direktionspräsidenten Heinrich Oswald. 
            </p>
            <Link to='/geschichte'>Mehr über die <b>Geschichte</b> erfahren</Link>
          </ReadMore>
        </SubPage>

      </StdPage>
    );
  }
}

export default Schule;