import React from 'react';

import './index.scss';

import StdPage from '../../comps/stdpage';
import SubPage from '../../comps/subpage'
import Content from '../../comps/content';
import Youtube from '../../comps/youtube'

//Fotos
import header from './media/header.jpg';

class UeberUns extends React.Component {
  render()
  {
    return (
        <StdPage title='Hä?' header={header} focusPoint='50% 28%'>

          <SubPage naviTitle=''>
            <Content>
              <h3>Da ist etwas schief gegangen!</h3>
              <h2>Seite nicht gefunden</h2>
              <p>Leider konnten wir die gewünschte Seite nicht auffinden. Als Entschuldigung zeigen wir Ihnen dafür dieses Video einer Baby-Ziege!</p>
              <Youtube videoid='vll33KEoCcc' />
            </Content>
          </SubPage>

      </StdPage>
    );
  }
}

export default UeberUns;