import React from 'react';

import './index.scss';

import StdPage from '../../comps/stdpage';
import SubPage from '../../comps/subpage'
import Content from '../../comps/content';
import Image from '../../comps/image'
import CollapseText from '../../comps/collapsetext';

//SubPages
import Gastredaktionen from './gastredaktionen';
import Klassenbesuche from './klassenbesuche';
import Icons from './icons';

//Literatur
import steiner from './media/steiner.jpg';

//Fotos
import header from './media/header.mp4';
import poster from './media/poster.jpg';
import unterricht1 from './media/unterricht1.jpg';

//PDF

class Schule extends React.Component {

  render()
  {
    return (
        <StdPage title='Ausbildung' video={header} poster={poster}>

          <SubPage naviTitle='Schulprogramm' className='bottomline'>
            <Content>
              <h3>Schulprogramm</h3>
              <h2>Handwerk</h2>

              <p>Talent reicht nicht. Selbst Hochtalentierte brauchen Handwerk. Der digitale Umbruch macht es noch komplexer. </p>
              <p>Die neuen Technologien revolutionieren den journalistischen Zugriff mit effizienten Methoden. So entstehen neue Berufsfelder, die im Programm einer modernen Schule auftauchen müssen.</p>
              <p>Schon vor Jahren drängten sich Ausbildungsmodule in Video-Journalismus auf: Kameraführung, der Einsatz des Funkmikrofons, die Schnittprogramme, die multimediale Produktion und multimediales Storytelling. </p>
              <p>Heute kommen Lektionen in Code Literacy, Data Driven Journalism und Datensicherheit dazu, und Sprech- und Stimmschulung fürs Live-Reporting.</p>
              <p>Die traditionellen Fertigkeiten bleiben aber fundamental: das Gespür für Themen, Hartnäckigkeit in der Recherche, Sensibilität für Sprache und Stil, die Disziplin zur Präzision bei den Fakten, Tiefenschärfe für die Einordnung, ein Flair für die Leichtigkeit im Unterhaltenden.</p>
              
              <Icons />

              <p>Die Ringier Journalistenschule entwickelt ihr Schulprogramm laufend weiter. Es vermittelt Schülerinnen und Schülern die notwendige Grundlage, auf die sie im journalistischen Berufsalltag angewiesen sind.</p>

              <CollapseText text='Das Schulprogramm thematisch gruppiert'>
                <b>Digitales Handwerk</b>
                <ul>
                  <li>Die Internet-Recherche</li>
                  <li>Social Content</li>

                  <li>Code Literacy, Data Driven Journalism</li>
                  <li>Datensicherheit, Dark Net</li>
                  <li>Crossmediales Storytelling </li>
                  <li>Smovies (Videos by smartphone), Storytelling Video</li>
                  <li>Live-Reporting, Sprech- und Stimmschulung</li>
                </ul>
                
                <b>Klassisches Handwerk - journalistische Formate</b>
                <ul>
                  <li>Die Recherche, Die Nachricht, Das Porträt, Das Interview, Die Reportage, Der Kommentar, Die Schlagzeile</li>
                  <li>Geschichten finden</li>
                  <li>Schreibwerkstatt, Storytelling</li>
                  <li>Das Bild, Bildagenturen, Bildsuche, Bildrechte</li>
                </ul>

                <b>Generalisten – Fachjournalisten</b>
                <ul>
                  <li>Onlinejournalismus</li>
                  <li>Boulevardjournalismus</li>
                  <li>Kriminalität, Justiz, Netzwerken</li>
                  <li>Politjournalismus</li>
                  <li>Wirtschaftsjournalismus, Bilanzen lesen</li>
                  <li>Peoplejournalismus</li>
                  <li>Sportjournalismus</li>
                  <li>Modejournalismus</li>
                  <li>Magazinjournalismus</li>
                  <li>Radiojournalismus</li>
                </ul>

                <b>Redaktionsalltag</b>
                <ul>
                  <li>Soft skills</li>
                  <li>Blattmache</li>
                  <li>Texte redigieren</li>
                </ul>

                <b>Medienrecht - Medienethik</b>
                <ul>
                  <li>Alles was Recht ist</li>
                  <li>Medienethik</li>
                  <li>Journalistenkodex, Presserat</li>
                </ul>

                <b>Verlagsbranche</b>
                <ul>
                  <li>Lesermarkt, Anzeigen-/Werbemarkt</li>
                  <li>Native Advertising</li>
                  <li>Dein eigenes Journalismus-Projekt</li>
                  <li>Freelancer</li>
                </ul>
              </CollapseText>

            </Content>

          </SubPage>

          <Image src={unterricht1} alt='Unterricht Schule' className='fullwidth'/>

          <SubPage naviTitle='Lohn' className='bottomline'>
            <Content>
              <h3>Die Lohnfrage</h3>
              <h2>Kein Schulgeld – im Gegenteil</h2>
              <p>
                Die Volontärinnen und Volontäre verdienen monatlich:<br />
                Im ersten Ausbildungsjahr: <b>CHF 1’625.-</b><br />
                Im zweiten Ausbildungsjahr: <b>CHF 3'250.-</b>
              </p>
            </Content>
          </SubPage>

          {/* <Image src={schanghai} alt='Ringier Schanghai' className='fullwidth'/> */}

          <SubPage naviTitle='Gastredaktion' className='bottomline'>
            <Content>
              <h3>Gastredaktion</h3>
              <h2>Luftveränderung</h2>
              <p>
                Die jungen Talente erklären bereits im Bewerbungsschreiben, auf welcher Redaktion sie das Ausbildungsvolontariat machen wollen. In der Regel wird genau diese ihre <b>Stammredaktion</b>.
                Während der zweijährigen Ausbildung dürfen sie zweimal für je drei Monate ausfliegen und sich eine <b>Gastredaktion</b> suchen. Sie können das intern oder extern machen.
                In den letzten Jahren haben sich drei Viertel der Lernenden vorübergehend in ein Auslandspraktikum verabschiedet. Ringier gefällt die Neugier der Jungen. Das Verlagshaus profitiert, wenn die Auszubildenden andere Redaktionskulturen erleben und so ihren Branchenhorizont erweitern. 
              </p>
            </Content>

            <Gastredaktionen />

          </SubPage>

          <SubPage naviTitle='Expeditionen' className='bottomline'>
            <Content>
              <h3>Vor Ort</h3>
              <h2>Vom Wert der Schuhsohle</h2>
              <p>
                In jeder Ausbildungswoche verlässt die Klasse das Schulzimmer und besucht Fabriken, Forschungsstätten, soziale und politische Institutionen. Diese Expeditionen ins analoge Leben balancieren den journalistischen Alltag in der virtuellen Welt am Laptop- oder Smartphone-Screen. 
              </p>

              <p>
                Die Klasse taucht in ganz unterschiedliche Arbeitswelten und Lebenswirklichkeiten ein. Die Absolventinnen und Absolventen lernen neue Menschen und ihre Geschichten kennen, reden mit  Mitarbeitern von der Basis bis zur Chefetage, vergrössern ihr Allgemeinwissen, erweitern ihr Netzwerk und arbeiten an der eigenen Sozialkompetenz. 
              </p>

              <p>Bisherige Klassen besuchten zum Beispiel:</p>

              <Klassenbesuche />

            </Content>
          </SubPage>

          <Image src={steiner} alt='Nicola Steiner, SRF Literaturclub' caption='Nicola Steiner, SRF Literaturclub' className='fullwidth'/>

          <SubPage naviTitle='Literatur'>

            <Content>
              <h3>Literatur</h3>
              <h2>Sensibilität für Sprache und Stil</h2>
              <p>Jeder Lehrgang hat seine eigene Begleiterin oder seinen Begleiter, der mit den Jungjournalisten am Gespür für Sprache und Stil arbeitet.</p>
              <p>In den vergangen Jahren haben folgende Persönlichkeiten die Literaturstunden übernommen:</p>

              <ul>
                <li><b>Nicola Steiner (aktuell)</b>, Literaturclub SRF</li>
                <li><b>Ursula Amrein</b>, Prof. für Deutsche Literatur Universität  Zürich</li>
                <li><b>Peter von Matt</b>, em. Prof. für Neuere Deutsche Literatur an der Universität Zürich und Schriftsteller</li>
                <li><b>Iris Radisch</b>, Deutsche Literatur-Journaranlistin DIE ZEIT</li>
              </ul>
            </Content>

          </SubPage>


      </StdPage>
    );
  }
}

export default Schule;