import React from 'react';
import './index.scss';

import widescreen from './media/widescreen.png';
import CloseButton from '../closebutton';

class YoutubeController extends React.Component {
  widescreen()
  {
    document.querySelector('.youtubestandaloneouter').style.display = 'block'
    document.querySelector('.youtubefader').style.display = 'block'
    //Fade in needs to be in a timer. No idea why
    setTimeout(() => {
      document.querySelector('.youtubefader').style.opacity = 0.7;
      document.querySelector('.youtubestandaloneouter').style.opacity = 1;
    }, 10)
  }

  closeOverlay()
  {
    this.props.onClose();
    document.querySelector('.youtubefader').style.opacity = 0;
    document.querySelector('.youtubestandaloneouter').style.opacity = 0;

    //Stop Video
    document.querySelector('.youtubestandalone iframe').contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')

    //Remove Fader
    setTimeout(() =>{
      document.querySelector('.youtubefader').style.display = 'none';
      document.querySelector('.youtubestandaloneouter').style.display = 'none';
    }, 600);
  }

  componentDidUpdate(prevProps, prevState)
  {
    if(this.props.activate && !prevProps.activate)
    {
      this.widescreen();
    }
  }

  render()
  {
    return (
      <React.Fragment>

        {/* Controller */}
        {/* <div id='youtubecontroller'>
          <img src={widescreen} alt='Ganzes Video anzeigen' onClick={() => this.widescreen()} />
        </div> */}

        {/* Video Widescreen */}
        <div className='youtubestandaloneouter'>
          <div className='youtubestandalone'>
            <CloseButton onClick={() => this.closeOverlay()} />
            <iframe width="560" title='youtubeplayer' height="315" src={`https://www.youtube.com/embed/${this.props.videoid}?version=3&enablejsapi=1`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>

        </div>
        <div className='youtubefader' onClick={() => this.closeOverlay()}></div>

      </React.Fragment>
    );
  }
}

class YoutubeControllerIcon extends React.Component {

  render()
  {
    return (
      <div id='youtubecontroller'>
        <img src={widescreen} alt='Ganzes Video anzeigen' onClick={() => this.props.onClick()} />
      </div>
    )
  }
}

export {
  YoutubeController,
  YoutubeControllerIcon,
}