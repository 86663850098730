import React from 'react';
import {withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom'
import imgLogo from './logo-new.png';
import Hamburger from '../hamburger';

import './index.scss';

function NavigationList(props)
{
  return (
    <ul>
    <li className={props.active === 'schule' ? 'active' : null}><Link to='schule'>Schule</Link></li>
    <li className={props.active === 'ausbildung' ? 'active' : null}><Link to='ausbildung'>Ausbildung</Link></li>
    <li className={props.active === 'bewerbung' ? 'active' : null}><Link to='bewerbung'>Bewerbung</Link></li>
    <li className={props.active === 'ueberuns' ? 'active' : null}><Link to='ueberuns'>Über uns</Link></li>
  </ul>
  )
}

class Navigation extends React.Component {

  state = {
    naviSize: 'big',
    hamburgerState: false
  }

  onScroll = () => {
    //Get Scrolltop (edge Workaround)
    var scrollTop = 0;
    if (window.pageYOffset !== undefined)
      scrollTop = window.pageYOffset;
    else 
    {
      var d = document, r = d.documentElement,b = d.body;
      scrollTop = r.scrollTop || b.scrollTop || 0;
    }

    if(scrollTop > 100 && this.state.naviSize !== 'small')
    {
      this.setState({'naviSize': 'small'})
    }
    else if(scrollTop < 100 && this.state.naviSize === 'small')
    {
      this.setState({'naviSize': 'big'})
    }
  }

  //Observe scrolling
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount()
  {
    window.removeEventListener('scroll', this.onScroll);
  }

  onHamburgerClick = (state) =>
  {
    this.setState({hamburgerState: state})
  }

  render()
  {
    return (
      <nav className={this.state.naviSize === 'small' ? 'navSmall' : ''}>

        {/* Logo */}
        <Link to='/'>
          <img src={imgLogo} id='logo' alt='Logo Ringier Journalistenschule' border={0}/>
        </Link>

        <Hamburger onClick={this.onHamburgerClick}/>

        {/* Navigation Desktop */}
        <div id='navigationdesktop'>
         <NavigationList active={this.props.location.pathname.substring(1)} />
        </div>

        {/* Navigation Mobile  */}
        <div id='navigationmobile' style={{display: this.state.hamburgerState ? 'block' : 'none'}}>
        <NavigationList active={this.props.location.pathname.substring(1)} />
        </div>
      </nav>
    )
  }
}

// export default Navigation;

export default withRouter(Navigation)