import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

import StdPage from "../../comps/stdpage";
import SubPage from "../../comps/subpage";
import Content from "../../comps/content";
import ReadMore from "../../comps/readmore";
import { PortraitContainer, Portrait } from "../../comps/portrait";

//Fotos
import header from "./media/header.jpg";
import hossli from "./media/hossli.png";
import sophie from "./media/sophie.jpg";

//Gallery
import frankameyer from "./media/stiftungsraete/frankameyer.jpg";
import haller from "./media/stiftungsraete/haller.jpg";
import kauz from "./media/stiftungsraete/kauz.jpg";
import posche from "../schule//media/dozenten/ulrike.jpg";
import liatowitsch from "./media/stiftungsraete/liatowitsch.png";

class UeberUns extends React.Component {
  render() {
    return (
      <StdPage title="Über uns" header={header} focusPoint="50% 28%">
        <SubPage naviTitle="Peter Hossli" className="bottomline">
          <Content>
            <Portrait src={hossli} className="center" />
            <h3>Schulleiter</h3>
            <h2>Peter Hossli</h2>
            <p>
              Seit Mai 2022 leitet Peter Hossli die Ringier Journalistenschule.
              Er ist seit über 30 Jahren im Journalismus tätig, recherchiert,
              erzählt, macht Fernsehen, schreibt und redet.
            </p>
            <p>
              Hossli wuchs im Kanton Aargau auf, studierte Geschichte,
              Filmwissenschaften und amerikanisches Recht an der Universität
              Zürich und der New York University. 1995 gehörte er zur
              Gründerredaktion des Schweizer Nachrichtenmagazins «Facts». Lange
              Zeit berichtete er aus den USA als Korrespondent für europäische
              Medien. Er war Reporter bei Ringier, bei der «NZZ am Sonntag» und
              Moderator und Produzent der Sendung Club bei SRF. 2018 erschien
              sein Buch «Die erste Miete ging an die Mafia. Was ich bin:
              Reporter», 2020 «Revolverchuchi: Mordfall Stadelmann».
            </p>
            <p>Er mag Filterkaffee und schwimmt gerne in kaltem Wasser.</p>
          </Content>
        </SubPage>

        <SubPage naviTitle="Sophie Stehli" className="bottomline">
          <Content>
            <Portrait src={sophie} className="center" />

            <h3>Mitarbeiterin</h3>
            <h2>Sophie Stehli</h2>
            <p>
              Seit April 2022 ist Sophie Stehli in einem Teilzeitpensum für Ringier Medien Schweiz tätig, seit Dezember 2022 zusätzlich bei der
              Ringier Journalistenschule. Zusammen mit Peter Hossli verantwortet
              sie das Blick-Mediacamp.
            </p>
            <p>
              Sophie Stehli wuchs in Zürich auf, studiert berufsbegleitend
              Kunsttherapie und arbeitete vor ihrer Zeit bei Ringier lange in
              der Kommunikationsbranche. Sie ist genau so gerne organisatorisch
              und administrativ wie kreativ tätig.
            </p>
            <p>
              Sie treibt gerne Sport (Tennis, Golf, Fitness) und mag lange Gespräche.
            </p>
          </Content>
        </SubPage>

        <SubPage naviTitle="Stiftung">
          <Content>
            <h2>Stiftung Hans Ringier</h2>
            <p>
              Am 15. Mai 1998 überführte der Stifter Hans Ringier die Schule
              unter das Dach der neugegründeten <b>Stiftung Hans Ringier</b>.
            </p>
            <p>Als Stiftungszweck hält die Grüdungsurkunde fest:</p>
            <p>
              <i>
                «Die Stiftung bezweckt die Förderung und Entwicklung des
                Schweizer Journalismus, insbesondere durch die theoretische und
                praktische Schulung, Ausbildung und Weiterbildung von
                Journalistinnen und Journalisten. Dabei sollen Professionalität,
                Ethik, Handwerk und Verantwortung erste Priorität bekommen.»
              </i>
            </p>

            <p>Die heutigen Stiftungsräte:</p>
          </Content>

          <PortraitContainer>
            <Portrait
              src={frankameyer}
              h1="Frank A. Meyer"
              h2="Präsident"
              h3="Ringier Publizist"
            />
            <Portrait
              src={liatowitsch}
              h1="Manuel Liatowitsch"
              h2="Vizepräsident"
              h3="Chief Legal Officer & Head Corporate Center Ringier AG"
            />
            <Portrait
              src={haller}
              h1="Michael Haller"
              h2="Mitglied"
              h3="em. Prof.  für Allgemeine und Spezielle Journalistik an der Universität Leipzig"
            />
            <Portrait
              src={posche}
              h1="Ulrike Posche"
              h2="Mitglied"
              h3="Stern-Reporterin und 
              -Autorin"
            />
            <Portrait
              src={kauz}
              h1="Magdalena Kauz"
              h2="Mitglied"
              h3="Dokumentarfilmerin, Sachbuchautorin und langjährige Leiterin des Bereichs Fachausbildung bei SRF"
            />
          </PortraitContainer>
        </SubPage>

        <SubPage naviTitle="Kontakt">
          <ReadMore>
            <h3>Ringier Journalistenschule</h3>
            <h2>Kontakt</h2>
            <p>
              Fragen, Anregungen, Kritik – Schreiben Sie uns! Alle Kanäle finden
              Sie auf unserer Kontakt-Seite.
            </p>
            <Link to="/kontakt">
              Die Ringier Journalistenschule <b>kontaktieren</b>
            </Link>
          </ReadMore>
        </SubPage>
      </StdPage>
    );
  }
}

export default UeberUns;
