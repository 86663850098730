import React from 'react';
import './index.scss';

/*
  Source: https://jonsuh.com/hamburgers/
*/

class Hamburger extends React.Component {

  state = {
    active: false
  }

  onClick()
  {
    this.props.onClick(!this.state.active);
    this.setState({active: !this.state.active});
  }

  render()
  {
    return (
      <button className={`hamburger hamburger--collapse ${this.state.active ? 'is-active' : ''}`} type="button" onClick={() => this.onClick()}>
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    )
  }
}

export default Hamburger;
