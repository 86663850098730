import React from 'react';
import Helpers from '../../helpers.js';
import './index.scss';

class SubPage extends React.Component {

  render()
  {
    return (
      <div id={Helpers.createKeyFromString(this.props.naviTitle)} className={`subpage ${this.props.padding === 'small' ? 'paddingsmall' : 'paddingnormal'} ${this.props.className ? this.props.className : ''}`}>
        {this.props.children}
      </div>
    );
  }
}

export default SubPage;
