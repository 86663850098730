import React from 'react';
import './index.scss';

class Columns extends React.Component {
  render()
  {
    return (
      <div className={`content ${this.props.className ? this.props.className : ''}`}>
        {this.props.children}
      </div>
    );
  }
}

export default Columns;
