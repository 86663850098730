import React from 'react';

import Gallery from '../../comps/gallery';

//Gallery
import novartis from './media/klassenbesuche/novartis.jpg';
import kloster from './media/klassenbesuche/kloster.jpg';
import druckerei from './media/klassenbesuche/druckerei.jpg';
import strafanstalt from './media/klassenbesuche/strafanstalt.jpg';
import bundeshaus from './media/klassenbesuche/bundeshaus.jpg';
import migration from './media/klassenbesuche/migration.jpg';

class Klassenbesuche extends React.Component {
  render()
  {
    return (
      <Gallery className='inlinegallery'>
        <div>
          <img src={novartis} alt='Novartis'/>
          <span>Campus des Lifescience-Konzerns <b>Novartis</b> in Basel</span>
        </div>

        <div>
          <img src={strafanstalt} alt='Strafanstalt Schöngrün'/>
          <span>Strafanstalt <b>Schöngrün</b></span>
        </div>

        <div>
          <img src={druckerei} alt='Druckerei Adligenswil'/>
          <span><b>Druckerei Adligenswil</b>, wo der BLICK bis November 2018 gedruckt wurde</span>
        </div>

        <div>
          <img src={kloster} alt='Kloster Einsiedeln'/>
          <span><b>Kloster Einsiedeln</b>, Diskussion mit dem Abt</span>
        </div>

        <div>
          <img src={bundeshaus} alt='Bundeshaus'/>
          <span><b>Bundeshaus</b> in Bern, Sitz des eidgenössischen Parlaments, des Bundesrats und der Bundesverwaltung</span>
        </div>

        <div>
          <img src={migration} alt='Schweizerische Flüchtlingshilfe'/>
          <span>Migrations-Modul <b>Schweizerische Flüchtlingshilfe</b></span>
        </div>

        

      </Gallery>

    );
  }
}

export default Klassenbesuche;