import React from 'react';

import './index.scss';

class CloseButton extends React.Component {

  render()
  {
    return (
      <div className="closebutton" onClick={() => this.props.onClick()}></div>
    )
  }

}

export default CloseButton