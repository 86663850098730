import React from 'react';
import './index.scss';
import Content from '../content'

class ReadMore extends React.Component {

  render()
  {
    return (
      <div className={`readmore ${!this.props.hideBorders ? 'borders' : ''}`}>
        <Content>
          {this.props.children}
        </Content>
      </div>
    );
  }
}

export default ReadMore;
