import React from 'react';
import './index.scss';
import CloseButton from '../closebutton';

class Image extends React.Component {

  // imageClick()
  // {
  //   document.querySelector('.imagefullscreenfader').style.display = 'block'
  //   document.querySelector('.imagefullscreen').style.display = 'block'

  //   //Fade in needs to be in a timer. No idea why
  //   setTimeout(() => {
  //     document.querySelector('.imagefullscreenfader').style.opacity = 0.8;
  //     document.querySelector('.imagefullscreen').style.opacity = 1;
  //   }, 10)
  // }

  // closeOverlay()
  // {
  //   document.querySelector('.imagefullscreen').style.opacity = 0;
  //   document.querySelector('.imagefullscreenfader').style.opacity = 0;

  //   //Remove Fader
  //   setTimeout(() =>{
  //     document.querySelector('.imagefullscreen').style.display = 'none';
  //     document.querySelector('.imagefullscreenfader').style.display = 'none';
  //   }, 600);
  // }

  render()
  {
    return (
      <React.Fragment>
        <div className={`stdimage ${this.props.className}`}>
          <img src={this.props.src} alt={this.props.alt}/>
          {this.props.caption &&
            <span>{this.props.caption}</span>
          }
        </div>

        <div className='imagefullscreenfader' />

        <div className='imagefullscreen' style={{backgroundImage: `url(${this.props.src})`}}>
          <CloseButton onClick={this.closeOverlay} />
          {/* <img src={this.props.src} alt={this.props.alt} /> */}
        </div>
      </React.Fragment>
    );
  }
}

export default Image;
