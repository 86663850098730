import React from 'react';
import './index.scss';

class Outline extends React.Component {
  render()
  {
    return (
      <div className='outline'>
        {this.props.children}
      </div>
    );
  }
}

export default Outline;
