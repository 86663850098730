import React from 'react';
import './index.scss';

function Portrait(props)
{
  return (
    <div className={`portrait ${props.className ? props.className : ''}`} style={{width: props.width}}>
      <div className='image'>
        <img src={props.src} alt={props.h1} />
      </div>
      <p className='h1'>{props.h1}</p>
      <p className='h2'>{props.h2}</p>
      <p className='h3'>{props.h3}</p>
    </div>
  )
}

class PortraitContainer extends React.Component {

  render()
  {
    var children = this.props.children;
    if(Array.isArray(this.props.children))
    {
      children = this.props.children.map((child, index) => {
        return React.cloneElement(child, {
          key: index,
          width: child.props.width ? child.props.width : Math.floor(100 / this.props.children.length) + '%'
       });
      })
    }
    
    return (
      <React.Fragment>
        <div className='portraitcontainer'>
          {children}
        </div>
        <div className='clearfix'></div>
      </React.Fragment>
    )
  }
}

export {
  Portrait,
  PortraitContainer
}