import React from 'react';
import './App.scss';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'

//Pages
import Home from './sites/home';
import Schule from './sites/schule';
import Alumni from './sites/alumni';
import Geschichte from './sites/geschichte';
import Rede from './sites/rede';
import Ausbildung from './sites/ausbildung';
import Villa from './sites/villa';
import Bewerbung from './sites/bewerbung';
import UeberUns from './sites/ueberuns';
import Kontakt from './sites/kontakt';
import Impressum from './sites/impressum';
import error404 from './sites/404';

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path='/schule' component={Schule} />
          <Route path='/alumni' component={Alumni} />
          <Route path='/geschichte' component={Geschichte} />
          <Route path='/villa' component={Villa} />
          <Route path='/ausbildung' component={Ausbildung} />
          <Route path='/rede' component={Rede} />
          <Route path='/bewerbung' component={Bewerbung} />
          <Route path='/ueberuns' component={UeberUns} />
          <Route path='/kontakt' component={Kontakt} />
          <Route path='/impressum' component={Impressum} />
          <Route component={error404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;