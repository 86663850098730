import React from 'react';
import './index.scss';

class Youtube extends React.Component {

  render()
  {
    return (
     <div className='youtubeembed'>
       <iframe width="100%" src={`https://www.youtube.com/embed/${this.props.videoid}`} title='Youtube-Video' frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
       {this.props.children}
     </div>
    )
  }
}

export default Youtube;
