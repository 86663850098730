import React from 'react';

import './index.scss';

import StdPage from '../../comps/stdpage';
import SubPage from '../../comps/subpage'
import Content from '../../comps/content';

//Fotos
import header from './../geschichte/media/frankameyer.jpg';

class Geschichte extends React.Component {
  render()
  {
    return (
        <StdPage title='«Freiheit ist Fragen»' header={header} focusPoint='59% 24%'>

          <SubPage naviTitle='Rede'>
            <Content>
              <h3>Jubiläumsrede</h3>
              <h2>Frank A. Meier </h2>
              <p>Am 8. Dezember 2014 feierte Ringier das 40-Jahre-Jubiläum seiner Journalistenschule mit einem rauschenden Fest im Pressehaus an der Dufourstrasse in Zürich.</p>
              <p>Über 300 Gäste erwiesen der Schule die Ehre. Die Verlegerfamilie durfte ein grosses Dankeschön für ihr ideelles und privat finanziertes Engagement entgegennehmen.</p>

              <p>Die Jubiläumsrede hielt Frank A. Meyer, Stiftungsratspräsident: «Freiheit ist Fragen». Die Rede im Wortlaut:</p>
              
              <div style={{fontStyle: 'italic'}}>
                <p>Sehr verehrte Damen und Herren</p>
                <p>Worüber wird geredet, wenn heute von Journalismus und Journalisten geredet wird? Über die Kompetenz, sich in der digitalen Medienwelt zurechtzufinden, sich den neuen, technisch bedingten Voraussetzungen anzupassen. Es geht darum, polyvalent zu sein, was bedeutet, den Anforderungen von Facebook, Instagram und Twitter zu genügen. Das Handwerk, das ja auf die Sprache hinausläuft, ist der zunehmenden Beschleunigung anzupassen.</p>
                <p>Das heisst, dass auch das Denken dieser Beschleunigung zu unterwerfen ist. Doch bin ich mir gar nicht mehr sicher, dass Denken und  Schreiben zwingend miteinander zu tun haben müssen, wenn ich auf die aktuelle Debatte blicke. </p>
                <p>Dass es bei allen Veränderungen auch – oder gar ausschliesslich – um «guten Journalismus» gehe, wird zwar immer wieder angefügt, aber, wie mir auffällt, meist am Schluss der schwungvollen Medienreden – beiläufig und pflichtschuldig. Mehr nicht.</p>
                <p>Könnte es nun aber sein, dass es noch um etwas anderes geht, das den Journalisten zu vermitteln wäre, insbesondere den jungen Journalisten? Könnte es sein, dass unserem Beruf Wichtigeres, ja Existenzielleres als Netzkompetenz ins Bewusstsein gerufen werden müsste? Ins journalistische Alltagsbewusstsein!</p>
                <p>Könnte es sein, dass es erneut ums Ganze geht?</p>
                <p>Ich sage erneut, weil wir doch seit dem Ende des Kalten Krieges goldene Freiheitsjahre erlebt haben. Jahre nach dem Motto: Es geht alles, und alles, was nicht geht, ist halb so schlimm.</p>
                <p>Ja, es war eine glückliche Zeit, eine glückliche Generation.</p>
                <p>Und nun, 25 Jahre nach dem Mauerfall? Nun sind wir Zeugen von Massakern, Kriegsdrohungen und Kriegen. Und zwar nicht irgendwo fern von uns, sondern in Europas unmittelbarer Nachbarschaft, sogar in Europa selbst.</p>
                <p>Die Bedrohungen, die uns aus allen Medien, täglich und stündlich, entgegenschwappen, haben nicht den Charakter regionaler Wirren. Dahinter stecken mächtige Mächte, die heraufbeschwören, was wir überwunden glaubten: Weltkonflikte.</p>
                <p>Sollte ich mit diesen Überlegungen nicht übertreiben, wäre hier doch ein dringliches Thema für die journalistische Debatte: Es ist nicht mehr alles «halb so schlimm». Nein, es geht wieder um etwas!</p>
                <p>Wenn es aber wieder um etwas geht: Worum geht es dann? Auf diese Frage müssten sich doch die Journalisten, jenseits des Tagesgeschäfts, einen Reim machen: fürs Tagesgeschäft.</p>
                <p>Ich habe es für mich versucht. Und ich bin auf einen Satz von Max Frisch gestossen, der vielleicht weiterhilft, womöglich ein Schlüssel zum befremdlichen Geschehen ist. Frisch formulierte ihn in seinem Tagebuch 1946:</p>
                <p>«Wir wollen gar keine Antwort, sondern wir wollen die Frage vergessen.»</p>
                <p>Ist es das, was uns gerade widerfährt? Erleben wir eine Zeit, erleben wir eine Welt, in der mit Macht und Gewalt und Kriegsdrohungen und Krieg Antworten erzwungen werden, weil man die Fragen nicht aushält? Ethnische Antworten, religiöse Antworten, ökonomische Antworten, technologische Antworten – Antworten also, die uns endlich und ein für alle Mal vergessen lassen, dass alles Leben eine Frage der Fragen ist?</p>
                <p>Die nationalistisch-völkische Ideologie ist eine Antwort, die keine Frage duldet; die religiöse Ideologie ist eine Antwort, die jede Frage als Häresie verfolgt.</p>
                <p>Wir schauen ratlos auf solche Antworten aus Zeiten, die uns längst verflossen schienen, endgültig überwunden. Und plötzlich – aus heiterem Himmel – mischt sich die vergangen geglaubte Vergangenheit wieder ein und behelligt unsere Zeit.</p>
                <p>Wir stehen diesem gestrigen Geschehen, das unseren Alltag in die Krise stürzt, ratlos gegenüber: Wie wehrt man sich gegen Vergangenheit, die mit allen Mitteln über die Gegenwart zu herrschen sich anschickt?</p>
                <p>Doch nicht nur verspätete Kulturen fordern uns mit ihren gewaltgeladenen Antworten heraus. Auch in unserer eigenen, so sehr dem Hier und Jetzt ergebenen Zivilisation sehen wir uns konfrontiert mit Verweigerern von Fragen.</p>
                <p>Der Marktradikalismus ist eine Antwort, die Fragen nicht kennt, beinhaltet sie doch die hermetische Lehre, dass alles gute Geschehen auf die Gesetze des Marktes zurückzuführen sei.</p>
                <p>Mittlerweile hat dieser ökonomische Fundamentalismus – recht eigentlich der Fundamentalismus des Westens – Konkurrenz erhalten. Durch den Glauben an eine technologisch endgültige Antwort. Durch eine Religion aus dem neuen, verheissenen Land «Silicon Valley». Durch das Heilsversprechen, wonach schon bald einmal ein Computer das Wissen der Welt vereinen und vernetzen und verarbeiten werde – auf dass endlich und bis ans Ende aller Tage Gott auf Erden walte und herrsche.</p>
                <p>Google-Gott!</p>
                <p>Dieser Glaube antwortet, wie der Begriff Gott besagt, den ich hier ganz bewusst verwende, auf eine uralte Sehnsucht:</p>
                <p>Die Sehnsucht, es möge sich irgendwie und irgendwo und durch irgendwen das Wissen über Richtig und Falsch, das Wissen über Gut und Böse offenbaren; die Sehnsucht, dieses Wissen möge uns erheben aus den Niederungen unserer ebenso unvollkommenen wie mühseligen Existenz.</p>
                <p>Die Sehnsucht auf die Erlösung von allem Fragen, von allem quälenden Für und Wider.</p>
                <p>Das Computergehirn, dieses finale Weltgehirn, ist allerdings nichts anderes als die kommunistische Vorstellung von der Allmacht des Zentralkomitees – durch ein paar passende Algorithmen ins Elektronische übertragen.</p>
                <p>Ja, schon die kommunistische Erlösungswissenschaft stellte sich ihre Vorkämpfer, die sie Avantgarde nannte, als eine Art Superhirn vor: Als einen Klerus, der alles Wissen und alle Intelligenz der Gesellschaft versammelt und verwaltet und vereinheitlicht und zentralisiert – selbstverständlich ausschliesslich zum Wohle eben dieser Gesellschaft.</p>
                <p>Neu ist in «Silicon Valley» allein die Methode, nämlich die Mathematik – Rechnen statt Denken.</p>
                <p>Das Verführerische jener alten und neuen Antworten, die uns endlich, endlich von allem Fragen befreien, ist nicht zu leugnen. Warum soll nicht ein nationalistisch-ethnisches Reinheitsgebot Völker ihrer Identität versichern? Warum soll nicht ein Gott seinen Gläubigen ewige Geborgenheit garantieren? Warum soll nicht eine radikale Marktdoktrin die Manager von aller Moral befreien? Warum soll nicht ein elektronisches Gehirn die Menschen von der Erbsünde erlösen?</p>
                <p>Was spricht eigentlich noch für die Kultur der Fragen, die Max Frisch mit seinem Tagebuch-Satz meint?</p>
                <p>Und was ist das überhaupt: die Kultur der Fragen?</p>
                <p>Es ist die Freiheit selbst.</p>
                <p>Es ist, lassen Sie es mich hier einmal ganz naiv formulieren, zuerst die Freiheit des Kindes, dann ist es die Freiheit des heranwachsenden Menschen, schliesslich ist es die  Freiheit des Bürgers: zu zweifeln, zu fragen, erneut zu zweifeln, erneut zu fragen – und alle Antworten zum Anlass zu nehmen, gleich wieder neue Fragen zu stellen, auch diese neuen Antworten nur als vorläufig zu verstehen, bis sie erneut widerlegt werden.</p>
                <p>Karl Popper nennt das «die offene Gesellschaft». In dieser – in unserer! – demokratisch strukturierten Offenheit kann man nicht nur die Regierung abwählen, wie Popper es postuliert, man kann auch Antworten abwählen, und damit sogar die eigenen Gewissheiten.</p>
                <p>Das Resultat dieser «offenen Gesellschaft» ist Bürgerlichkeit, ist Bürgersein, ist der Bürger.</p>
                <p>Der freie Bürger.</p>
                <p>Was aber vermag die Fragerei dieses freien Bürgers gegenüber dem Furor der Antworten, wie wir ihm gerade ausgesetzt sind? Wirken wir da nicht zögerlich, zimperlich, schwächlich – gegenüber der verheissungsgeladenen Entschlossenheit all derer, die längst eine Antwort gefunden zu haben glauben? Ist die komplexe bürgerliche Demokratie mit ihrem ausdifferenzierten Rechtsstaat nicht überfordert? Ist sie nicht ineffizient? Ist sie nicht zu langsam?</p>
                <p>Verhandeln wir nicht täglich Fragen, die höchstens zu mühseligen Kompromissen führen – und sogleich wieder frische Fragen provozieren?</p>
                <p>Erlauben Sie mir angesichts dieser schwierigen Ausgangslage eine Formulierung, die Ihnen zunächst völlig paradox erscheinen wird:</p>
                <p>Die Demokratie ist die einzige denkbare Antwort auf die Fragen dieser Zeit und dieser Welt – aus einem ganz einfachen Grund: Weil die Demokratie selbst keine Antwort enthält! Weil sie ausschliesslich einen zivilen, praktikablen Rahmen bietet und fortwährendes Fragen  gewährleistet.</p>
                <p>Der Philosoph Ernst Bloch sprach von «Entwurfsfantasie» als einem zentralen Kriterium der Demokratie. Er hat recht. Denn das ist ja das Wunderbare an der Konstruktion des modernen demokratischen Rechtsstaats: Er ist erfüllt von unseren Entwürfen, unseren Hoffnungen, unseren Sehnsüchten. Sie machen die offene Gesellschaft aus – und stossen dabei auf die Entwürfe und die Hoffnungen und die Sehnsüchte anderer.</p>
                <p>Auf endgültige Antworten allerdings läuft das alles nicht hinaus. Ganz im Sinne von Max Frischs Satz lassen sich die Fragen in einer Demokratie nicht von den alles beantwortenden Antworten verdrängen. Sie sind immer da. Und sie entstehen immer wieder neu: manchmal drängend, manchmal latent, manchmal durch vorläufige Antworten scheinbar aufgehoben.</p>
                <p>Denn immer dort, wo der andere ins Spiel kommt, kommen Fragen ins Spiel.</p>
                <p>Die Demokratie lebt vom Eros der Fragen. Eros aber entsteht erst durch den anderen: durch unsere Neugierde auf diesen anderen. Ja, es ist in der Demokratie wie in der Liebe: Sie funktioniert nicht ohne die Lust auf den anderen.</p>
                <p>Ich wage sogar zu sagen: Demokratie lässt Liebe zu, fordert sie heraus, braucht sie vielleicht sogar als Voraussetzung. Die politisch-emotionalen Begriffe dazu sind Neugier und Begeisterung, Kreativität und Leidenschaft.</p>
                <p>Denn unsere einzige unumstössliche Gewissheit ist die: dass in der Demokratie die Fragen gestellt werden.</p>
                <p>Ich will dazu ein ganz einfaches Beispiel anführen: Das Zentralkomitee der kommunistischen Partei Chinas, dieses in manchen Wirtschaftskreisen so verehrte Zentralkomitee, glaubt Antworten auf alle Fragen zu haben. Doch wenn die Mitglieder eben dieses Superhirns für 1,4 Milliarden Menschen auf die Stras-se treten, müssen sie einen weissen Mundschutz umbinden, weil ihnen der Smog in Peking sonst den Atem raubt.</p>
                <p>In unserer offenen Gesellschaft hätten die Bürgerinnen und Bürger mit ihren Fragen längst für Abhilfe gesorgt: in Umweltpolitik und Industriepolitik und Gesundheitspolitik.</p>
                <p>Das ist das Grossartige – ich wiederhole: das Grossartige! – an der Demokratie: Niemand, der in ihr mitwirkt, bleibt von Fragen verschont. Und wer es dennoch darauf anlegt, von Fragen verschont zu bleiben, sieht sich meist seinerseits ziemlich bald in Frage gestellt.</p>
                <p>Denn die Demokratie ist das Gegenteil eines Heilsversprechens, sei es ein nationalistisches, ein völkisches, ein religiöses, ein ökonomisches oder ein digitales Heilsversprechen. Sie ist, wie der Demokratie-Philosoph Popper es formuliert: das System von Versuch und Irrtum.</p>
                <p>Mit der Wirkung ihrer Fragen zersetzt die Demokratie die Antworten – ein Prozess der fortwährenden Befreiung.</p>
                <p>Wir können diese Funktionsweise unserer Demokratie ganz konkret erleben: Mit unseren persönlichen Überzeugungen und Gewissheiten, aber auch mit unseren persönlichen Fragen stossen wir in ihr immer wieder auf die Überzeugungen und Gewissheiten und Fragen der anderen.</p>
                <p>Die Demokratie erzwingt stets und ganz grundsätzlich den Konflikt mit dem anderen, dem Andersdenkenden. Dieser Konflikt bringt die Fragen hervor. Und Lösungen. Allerdings nie die ein für alle Mal gültigen.</p>
                <p>Man könnte es auch so formulieren: Die unablässige demokratische Fragerei führt systematisch zur Ent-Täuschung – in zwei Wörtern geschrieben: Ent-Täuschung. Ja, Überzeugungen sind immer Täuschungen, weil sie auf eine Vielzahl anderer Überzeugungen treffen – und sich in der Debatte der offenen Gesellschaft in Fragen auflösen.</p>
                <p>Was für ein mühseliger Prozess! Was für eine Arbeit!</p>
                <p>Denkarbeit.</p>
                <p>Die Demokratie fordert viel von ihren Bürgerinnen und Bürgern. Sie fordert sogar die Hinnahme von Ent-Täuschungen, also ganz allgemein das Aushalten der Enttäuschung über die Demokratie, die sich allen grossen und endgültigen Antworten standhaft verweigert. Und uns auf das Vorläufige festlegt, was aufgrund der Vielfalt von Fragen zudem meist nur auf äusserst provisorische Kompromisse hinausläuft.</p>
                <p>Wie schön wäre doch das Ende all der vielen Fragerei! Wie erleichternd! Wie erhebend!</p>
                <p>Sie mögen diese meine Schilderung der konkreten, praktischen, unspektakulären und bisweilen ermüdenden Wirklichkeit der Demokratie für eine Banalität halten. Ich lasse mich gerne auf diesen Vorwurf ein:</p>
                <p>Es stimmt, die Demokratie ist banal!</p>
                <p>Sie ist die Banalität des Guten.</p>
                <p>Der Philosoph Odo Marquard hat die politische Kultur der Demokratie einmal mit folgenden Worten geschildert:</p>
                <p>«Die liberale Bürgerwelt bevorzugt das Mittlere gegenüber den Extremen, die kleinen Verbesserungen gegenüber der grossen Infragestellung, das Alltägliche gegenüber dem Moratorium des Alltags, das Geregelte gegenüber dem Erhabenen, die Ironie gegenüber dem Radikalismus, die Geschäftsordnung gegenüber dem Charisma, das Normale gegenüber dem Enormen, das Individuum gegenüber der Heilsgesellschaft.»</p>
                <p>All dieses Unspektakuläre aber ist das Resultat einer Kultur des Fragens.</p>
                <p>Nein, nichts ist spektakulär an der Demokratie. Es sei denn, dass inmitten all des Spektakels rundumher das Unspektakuläre plötzlich als spektakulär wahrgenommen wird.</p>
                <p>Der Verzicht auf pompöse Gewissheiten strengt an, denn Fragen bedeuten Brüche: Aufbrüche, Durchbrüche.</p>
                <p>Sind wir mit unserer Ethik des Fragens den gewalthaltigen, nationalistisch-völkischen und religiösen Antworten gewachsen, die aus verwehter Vergangenheit ebenso unerwartet wie aggressiv unsere Gegenwart erschüttern?</p>
                <p>Sind wir den infantilen ökonomischen und technologischen Antworten gewachsen, die uns, getarnt als wissenschaftliche Bäume der Erkenntnis, zum Sündenfall verführen wollen? Zum Sündenfall wider die freie, die demokratische, die offene Gesellschaft – zum Verzicht auf Fragen.</p>
                <p>Damit bin ich bei uns Journalisten und Journalistinnen angelangt. Haben wir verstanden, dass es plötzlich wieder um etwas geht? Um viel geht? Um sehr viel? Vielleicht sogar ums Ganze?</p>
                <p>Haben wir verstanden, dass unsere Arbeit nicht einfach nur den Kriterien von Spannung, Unterhaltung und Glamour zu dienen hat? Haben wir verstanden, dass der Facebook-Daumen, dieses einfältige «Gefällt mir», kein befriedigendes Kriterium ist? Haben wir verstanden, dass auch das Wohlgefühl des Verkaufserfolgs den Herausforderungen unserer Gegenwart nicht genügt?</p>
                <p>Ich weiss, die letzte Frage, die nach Auflage, Umsatz und Gewinn, müsste ich auch den Verlegern stellen, oder besser: ihrem dürftigen Ersatz, den Medien-Managern. Doch ich bleibe lieber bei uns, den Journalisten.</p>
                <p>In unseren Händen nämlich liegt das Schicksal der ent-täuschenden Demokratie, die gerade deshalb so viele Bürger enttäuscht, weil sie auf den ersten Blick so nervtötend unglamourös wirkt. Es ist die grosse Kunst unseres Denkhandwerks, Begeisterung zu wecken für die Kultur des Fragens – für die Gegenkultur zur Versuchung durch endgültige Antworten.</p>
                <p>Doch wie kann das gelingen? Wie sollen wir das tun?</p>
                <p>Indem wir das tun, was Journalismus bedeutet: genau hinschauen, genau erzählen, genau wiedergeben. Und dann die Fragen der Bürgerinnen und Bürger wiedergeben. Und schliesslich eigene Fragen stellen.</p>
                <p>So kleinteilig uns die Demokratie im Alltag erscheinen mag, ganz besonders im Kontrast zur grosssprecherischen Kultur der Antworten, so lebendig ist sie, wenn wir genau hinschauen: grosses Theater, mit Auf- und Abtritten, mit Irrungen und Wirrungen – ein Stück von Dürrenmatt.</p>
                <p>Es wird jeden Tag, jede Stunde, jede Minute gespielt. Wir Journalisten müssen nur den Vorhang heben.</p>
                <p>Dann halten wir dem Ansturm und der Verführung durch die grossen, leeren Antworten stand.</p>
                <p>Lassen Sie mich zum Schluss einen Satz des kämpferischen deutschen Aufklärers, Schriftstellers und Journalisten Ludwig Börne zitieren.</p>
                <p>Er lautet: «Man kann eine Idee durch eine andere verdrängen, nur die Freiheit nicht.»</p>
                <p>Freiheit ist Fragen.</p>

              </div>


            </Content>
          </SubPage>


      </StdPage>
    );
  }
}

export default Geschichte;